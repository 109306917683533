import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import { func, object } from 'prop-types';
import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { USER_BY_NAME, GROUPS } from '@graphQL/queries';
import { CREATE_GROUP, UPDATE_GROUP } from '@graphQL/mutations';
import Text from '@common/Text';
import TextField from '@common/Form/TextField';
import Checkbox from '@common/Form/Checkbox';
import Button, { SIZE } from '@common/Button';
import { createToast, TOAST_TYPES } from '@common/Toast';
import UserCheckbox from '@components/authenticated/Groups/components/NewGroupCreator/UserCheckbox';

import {
  ButtonRow,
  Form,
  Header,
  ScrollableList,
  UserSelector,
  CurrentUsersList,
} from '@components/authenticated/Groups/components/NewGroupCreator/style';

const NewGroupCreator = ({ closeModal, group }) => {
  const client = useApolloClient();
  const [users, setUsers] = useState([]);
  const [editMode] = useState(!isEmpty(group));

  let debounce = null;

  const searchClient = (ev) => {
    try {
      const inputValue = ev.currentTarget.value;
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        if (inputValue.length >= 3) {
          const { data } = await client.query({
            query: USER_BY_NAME,
            variables: {
              name: inputValue,
            },
            fetchPolicy: 'network-only',
          });

          setUsers(get(data, 'users.edges', []));
        }
      }, 300);
    } catch (error) {
      createToast(error.message, TOAST_TYPES.ERROR);
    }
  };

  const initialValues = {
    name: group.name || '',
    isPrivate: group.isPrivate || false,
    userSearch: '',
    users: [],
  };

  if (editMode) {
    delete initialValues.isPrivate;
    group.users.edges.forEach((u) => initialValues.users.push(u.node.id));
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          const variables = {
            input: {
              ...omitBy(values, (value, key) => key === 'userSearch'),
            },
          };

          if (editMode) {
            variables.input.group = group.id;
          }
          await client.mutate({
            mutation: editMode ? UPDATE_GROUP : CREATE_GROUP,
            variables,
            refetchQueries: [{ query: GROUPS }],
          });
          createToast(
            `${values.name} group was successfully ${
              editMode ? 'updated' : 'created'
            }`,
            TOAST_TYPES.SUCCESS,
          );

          closeModal();
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
        }
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Header>
            <Text type="H3">{editMode ? 'Edit ' : 'Create new '}group</Text>
          </Header>
          <TextField
            name="name"
            label="Group name"
            placeholder="e.g. Law Society"
          />
          {!editMode ? (
            <Checkbox name="isPrivate" label="Private" labelPosition="prefix" />
          ) : null}

          {values.isPrivate || group.isPrivate ? (
            <UserSelector>
              {editMode && group.users.edges.length ? (
                <>
                  <Text type="Text" color={200}>
                    Current users
                  </Text>
                  {group.users.edges.length ? (
                    <CurrentUsersList>
                      {group.users.edges.map(({ node: user }) => (
                        <UserCheckbox user={user} key={`user-${user.id}`} />
                      ))}
                    </CurrentUsersList>
                  ) : null}
                </>
              ) : null}

              <TextField
                label="Add users"
                name="userSearch"
                icon="magnifier"
                placeholder="Search for user"
                onChange={searchClient}
              />
              {users.length ? (
                <ScrollableList>
                  {users.map(({ node: user }) => (
                    <UserCheckbox user={user} key={`user-${user.id}`} />
                  ))}
                </ScrollableList>
              ) : null}
            </UserSelector>
          ) : null}

          <ButtonRow>
            <Button
              type="submit"
              text={editMode ? 'Update' : 'Save'}
              icon="arrow-right"
              size={SIZE.LARGE}
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </ButtonRow>
        </Form>
      )}
    </Formik>
  );
};

NewGroupCreator.defaultProps = {
  group: null,
};

NewGroupCreator.propTypes = {
  closeModal: func.isRequired,
  group: object,
};

export default NewGroupCreator;
