import styled from '@emotion/styled';
import { Form as FormikForm } from 'formik';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;

export const Main = styled.div`
  flex: 1;
  padding-right: 16px;
`;

export const Header = styled.div`
  display: flex;
  height: 103px;
  align-items: center;
  color: #fff;
  justify-content: space-between;
`;

export const Form = styled(FormikForm)`
  width: 100%;
  max-width: 350px;
`;
