import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_USER_ACCOUNT_TYPE } from '@graphQL/mutations';
import { USER, USER_TYPES } from '@graphQL/queries';

import { createToast, TOAST_TYPES } from '@common/Toast';
import Select from '@components/common/Form/Select';
import Button, { VARIANT, SIZE } from '@common/Button';
import { Form } from '@components/authenticated/UserSettings/style';

const ChangeAccountType = ({ user }) => {
  const [updateUserAccountType] = useMutation(UPDATE_USER_ACCOUNT_TYPE);
  const { data: userTypesQuery } = useQuery(USER_TYPES);

  const ut = get(userTypesQuery, 'userTypes');
  const userTypes = map(ut, (item) => ({ label: item.name, value: item.id }));

  return (
    <Formik
      initialValues={{ user: user.id, userType: user.userType.id }}
      validate={(values) => {
        const errors = {};
        if (!values.userType) {
          errors.userType = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await updateUserAccountType({
            variables: { input: { ...values } },
            refetchQueries: [
              {
                query: USER,
                variables: {
                  id: user.id,
                },
              },
            ],
          });

          createToast(
            `${user.firstName} ${user.lastName}'s account type was successfully updated`,
            TOAST_TYPES.SUCCESS,
          );
          setSubmitting(false);
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Select name="userType" options={userTypes} />
          <Button
            type="submit"
            text="Save changes"
            icon="disk"
            iconPosition="prefix"
            variant={VARIANT.PRIMARY}
            size={SIZE.LARGE}
            loading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

ChangeAccountType.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    userType: PropTypes.shape({
      __typename: PropTypes.string,
      name: PropTypes.string,
    }),
    __typename: PropTypes.string,
  }).isRequired,
};

export default ChangeAccountType;
