import React from 'react';
import PropTypes from 'prop-types';

import { clear } from '@storage';
import Text from '@common/Text';

import { Wrapper, LogoutButton } from './style';

const UserBox = ({ history, username }) => (
  <Wrapper>
    <Text type="H5" color="white">
      Welcome, {username}
    </Text>
    <LogoutButton
      type="button"
      onClick={() => {
        clear();
        setTimeout(() => {
          history.push('/login');
        }, 1);
      }}
    >
      <img src="images/icons/user-white.svg" alt="" />
      Logout
    </LogoutButton>
  </Wrapper>
);

UserBox.propTypes = {
  history: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
};

export default UserBox;
