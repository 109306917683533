import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import routes from '@routes';
import { createToast, TOAST_TYPES } from '@common/Toast';

const NotFound = () => {
  useEffect(() => {
    createToast('Route not found', TOAST_TYPES.INFO);
  });
  return <Redirect to={routes.home} />;
};

export default NotFound;
