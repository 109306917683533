import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Text from '@common/Text';
import InfoTip from '@common/InfoTip';

import { Error, Input, Label, Wrapper, Control, Handle } from './style';

const Checkbox = ({ label, labelPosition, infoTip, ...props }) => {
  const [field, meta] = useField(props);

  const fieldId = `field_${field.name}`;
  const hasError = meta.touched && meta.error;

  return (
    <Wrapper withError={hasError}>
      <Input
        id={fieldId}
        withError={hasError}
        type="checkbox"
        {...field}
        {...props}
      />
      <Label htmlFor={fieldId}>
        {labelPosition === 'prefix' ? <Text color={200}>{label}</Text> : null}
        <Control>
          <Handle />
        </Control>
        {labelPosition === 'suffix' ? <Text color={200}>{label}</Text> : null}

        {infoTip ? <InfoTip tip={infoTip} /> : null}
      </Label>

      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </Wrapper>
  );
};

Checkbox.defaultProps = {
  infoTip: null,
  labelPosition: 'suffix',
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  infoTip: PropTypes.string,
  labelPosition: PropTypes.oneOf(['prefix', 'suffix']),
};

export default Checkbox;
