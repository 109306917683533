import styled from '@emotion/styled';

export const FilesIcon = styled.div`
  background-color: #fff;
  width: 128px;
  height: 128px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  img {
    display: block;
    object-fit: contain;
    width: 60px;
  }
`;

export const Row = styled.div`
  line-height: 20px;
  margin-bottom: 30px;
`;
