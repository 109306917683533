import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import uniqueId from 'lodash/uniqueId';
import routes from '@routes';
import { RENEW_ACCESS_TOKEN } from '@graphQL/mutations';

import CloseButton from './components/CloseButton';

const SESSION_TOAST_CONTAINER_ID = 'session-expired-toasts';
const TOAST_CONTAINER_ID = 'other-toasts';
const RENEW_SESSION_CONTAINER_ID = 'renew-session-toasts';
export const SESSION_EXPIRED_MESSAGE = 'Your session has expired';
export const TOAST_TYPES = toast.TYPE;

const activeToasts = [];

export const createToast = (message, type) => {
  let m = message;
  if (type === toast.TYPE.ERROR) {
    m = `Error: ${m}`;
  }

  if (activeToasts.indexOf(message) !== -1) {
    return null;
  }

  let toastId = uniqueId('toast-');
  let containerId = TOAST_CONTAINER_ID;

  // prevent showing toast before session renewal
  if (
    message === 'Signature has expired' ||
    message === 'Error decoding signature'
  ) {
    return null;
  }

  if (message === SESSION_EXPIRED_MESSAGE) {
    toastId = 'session-expired-toast';
    containerId = SESSION_TOAST_CONTAINER_ID;
  }

  if (type === TOAST_TYPES.ERROR) {
    activeToasts.push(message);
  }

  const onCloseHandler = () => {
    if (type === TOAST_TYPES.ERROR) {
      const removeIndex = activeToasts.indexOf(message);
      activeToasts.splice(removeIndex, 1);
    }
  };

  return toast(m, {
    toastId,
    type,
    closeButton: CloseButton,
    position: 'top-center',
    containerId,
    onClose: onCloseHandler,
  });
};

const ToastWrapper = ({ set }) => {
  const [renewAccessToken] = useMutation(RENEW_ACCESS_TOKEN);
  const history = useHistory();

  useEffect(() => {
    toast.onChange((numberOfToastDisplayed, containerId) => {
      // redirect to login page on session expired (only if user is out of auth routes)
      if (
        containerId === SESSION_TOAST_CONTAINER_ID &&
        Object.values(routes.auth).indexOf(history.location.pathname) === -1
      ) {
        history.push(routes.auth.signIn);
      }
    });
  }, [history, renewAccessToken]);

  return (
    <>
      <ToastContainer
        enableMultiContainer
        containerId={SESSION_TOAST_CONTAINER_ID}
      />
      <ToastContainer enableMultiContainer containerId={TOAST_CONTAINER_ID} />
      <ToastContainer
        enableMultiContainer
        containerId={RENEW_SESSION_CONTAINER_ID}
      />
    </>
  );
};

export default ToastWrapper;
