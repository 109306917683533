import styled from '@emotion/styled';
import { getPalette } from '@styles/helpers';

export const Title = styled.div`
  margin-bottom: 40px;
`;

export const LoaderWrapper = styled.div`
  background-color: #fff;
  width: 128px;
  height: 128px;
  border-radius: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  h6 {
    position: absolute;
    bottom: 20px;
  }
`;

export const Circle = styled.svg`
  margin-bottom: 5px;

  .circle-background,
  .circle-progress {
    fill: none;
  }

  .circle-background {
    stroke: transparent;
  }

  .circle-progress {
    stroke: ${(props) => getPalette(props).base};
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`;
