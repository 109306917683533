import React, { useState } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { getItem, KEYS } from '@storage';

import routes from '@routes';

const Base = ({ component: RouteComponent, routeProps }) => {
  const [isAuthenticated, setAuthenticated] = useState(
    !!getItem(KEYS.AUTH_TOKEN),
  );

  if (isAuthenticated !== !!getItem(KEYS.AUTH_TOKEN)) {
    setAuthenticated(!!getItem(KEYS.AUTH_TOKEN));
  }

  return (
    <Route
      {...routeProps}
      render={() =>
        isAuthenticated ? (
          <Redirect to={routes.home} />
        ) : (
          <Redirect to={routes.auth.signIn} />
        )
      }
    />
  );
};

export default withRouter(Base);
