import React from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGIN } from '@graphQL/mutations';
import { setItem, KEYS } from '@storage';

import { createToast, TOAST_TYPES } from '@common/Toast';
import TextField from '@components/common/Form/TextField';
import Button, { VARIANT, SIZE } from '@common/Button';
import routes from '@routes';
import { Form, Row } from './style';

const SignInForm = ({ history }) => {
  const [signInHandler] = useMutation(LOGIN);

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        }
        if (!values.password) {
          errors.password = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          const {
            data: {
              login: { validCredentials, ...resp },
            },
          } = await signInHandler({
            variables: { input: { ...values } },
          });

          if (!validCredentials) {
            setErrors({
              email: ' ',
              password: ' ',
            });
            createToast('Invalid credentials', TOAST_TYPES.ERROR);
          } else {
            const { authenticationStep } = resp;

            setItem(KEYS.TOKEN_2_FA, authenticationStep.token);
            setSubmitting(false);
            history.push(routes.auth[authenticationStep.nextStep]);
          }
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <TextField
            name="email"
            label="Email"
            placeholder="e.g. joe@bloggs.co.uk"
            icon="user"
          />
          <TextField
            name="password"
            type="password"
            label="Password"
            placeholder="*********"
            toggleable
          />
          <Row inline>
            <Button
              type="submit"
              text="Login"
              icon="arrow-right"
              variant={VARIANT.PRIMARY}
              size={SIZE.LARGE}
              loading={isSubmitting}
            />
          </Row>
          <Row>
            <Button
              type="button"
              text="Forgot password"
              icon="arrow-right-dark"
              variant={VARIANT.SECONDARY}
              size={SIZE.MEDIUM}
              onClick={() => history.push(routes.auth.forgotPassword)}
              disable={isSubmitting}
            />
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(SignInForm);
