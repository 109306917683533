import { gql } from '@apollo/client';

export const RENEW_ACCESS_TOKEN = gql`
  mutation($input: RenewAccessTokenInput!) {
    renewAccessToken(input: $input) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`;
