import { gql } from '@apollo/client';

export const UPDATE_DOCUMENT = gql`
  mutation($input: UpdateDocumentMutationInput!) {
    updateDocument(input: $input) {
      document {
        documentType {
          name
        }
        status
      }
    }
  }
`;
