import styled from '@emotion/styled';

export const Button = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
