import styled from '@emotion/styled';
import { getColors, getPalette } from '@styles/helpers';

export const Label = styled.label`
  display: block;
  margin-bottom: 7px;
`;

export const Wrapper = styled.div`
  color: ${(props) => (props.withError ? getColors(props).error : '#585757')};
  width: 100%;
  margin-bottom: ${(props) => (props.single ? 0 : '30px')};
  position: relative;

  ${(props) =>
    props.withError
      ? `
    ${Label} {
      span {
        color: ${getColors(props).error}
      }
    }
  `
      : null}
`;

export const Error = styled.div`
  color: ${(props) => getColors(props).error};
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  bottom: -24px;
`;

export const Input = styled.input`
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.withError ? getColors(props).error : '#DBDBDB'};
  font-size: ${(props) => (props.textSize === 'large' ? '20px' : '15px')};
  line-height: ${(props) => (props.textSize === 'large' ? '30px' : '22px')};
  color: ${(props) =>
    props.withError ? getColors(props).error : getColors(props).text[200]};
  height: ${(props) => (props.textSize === 'large' ? '48px' : '38px')};
  padding: 9px 15px;
  width: 100%;
  transition: border-color 0.3s ease-in-out;

  ${(props) => (props.withIcon ? `padding-right: 40px;` : null)}
  ${(props) =>
    props.as === 'textarea'
      ? `resize: vertical; height: 256px;`
      : null}

  ::-webkit-input-placeholder {
    color: ${(props) => getColors(props).text[200]};
    opacity: 0.4;
  }

  :-ms-input-placeholder {
    color: blue;
    color: ${(props) => getColors(props).text[200]};
    opacity: 0.4;
  }

  ::placeholder {
    color: blue;
    color: ${(props) => getColors(props).text[200]};
    opacity: 0.4;
  }

  &:hover {
    border-color: #b7b7b7;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 1px
      ${(props) =>
        props.withError ? getColors(props).error : getPalette(props).base};

    border-color: ${(props) =>
      props.withError ? getColors(props).error : getPalette(props).base};
  }

  &:disabled {
    background-color: #f7f7f7;
    border-color: #f7f7f7;

    &:hover {
      border-color: #f7f7f7;
    }
  }
`;

export const CrossLine = styled.span`
  width: 100%;
  height: 2px;
  background: #005ad4;
  transform: rotate(26deg);
  position: absolute;
  left: 0;
  top: 6px;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
`;

export const SwitchReadable = styled.button`
  display: block;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 5;
  position: absolute;
  right: 14px;
  top: 46px;
  padding: 0;

  &:focus {
    outline: none;
  }

  img {
    transition: opacity 0.3s ease-in-out;
  }

  ${(props) =>
    props.readable
      ? `
      img {
        opacity: .4;
      }

      ${CrossLine} {
        opacity: 1;
      }
      `
      : null}
`;

export const Icon = styled.img`
  display: block;
  border: none;
  background-color: transparent;
  cursor: ${(props) => (props.onClick ? 'pointer' : null)};
  z-index: 5;
  position: absolute;
  right: 14px;
  top: ${(props) => (props.withLabel ? 46 : 14)}px;
  padding: 0;
`;

export const ClearButton = styled.button`
  display: block;
  position: absolute;
  right: ${(props) => (props.withIcon ? '30px' : '14px')};
  top: 8px;
  padding: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.65, -0.29, 0.01, 2.12),
    opacity 0.15s ease-in-out;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-50%);

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
`;
