import styled from '@emotion/styled';
import { Form as FormikForm } from 'formik';

export const Form = styled(FormikForm)`
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  padding: 28px 0 88px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 30px;
  }
`;

export const Header = styled.div`
  margin-bottom: 40px;
`;
