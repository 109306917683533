import React, { useState } from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GROUP } from '@graphQL/queries';
import { createToast, TOAST_TYPES } from '@common/Toast';
import Documents from '@components/authenticated/Documents';
import SearchHeader from '@components/authenticated/SearchHeader';
import Layout from '@components/authenticated/layout';

const RECORDS_PER_PAGE = 12;

const GroupDocuments = () => {
  const { groupId } = useParams();
  const [initialized, setInitialized] = useState(false);
  const [firstCursor, setFirstCursor] = useState('');
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [variables, setVariables] = useState({
    groupId,
    searchTerm: '',
    orderBy: '-uploadDate',
    first: RECORDS_PER_PAGE,
  });

  const { loading, error, data } = useQuery(GROUP, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: ({
      group: {
        documents: { pageInfo },
      },
    }) => {
      if (!initialized) {
        setFirstCursor(pageInfo.startCursor);
        setInitialized(true);
      }

      if (pageInfo.startCursor === firstCursor && hasPrevPage) {
        setHasPrevPage(false);
      }
    },
  });

  if (error) {
    createToast(error.message, TOAST_TYPES.ERROR);
  }

  const documents = get(data, 'group.documents.edges', []);
  const pageInfo = get(data, 'group.documents.pageInfo', {});
  const group = get(data, 'group', {});

  const search = (input) => {
    setVariables((v) => ({ ...v, searchTerm: input }));
  };

  const clearSearch = () => {
    setVariables((v) => ({ ...v, searchTerm: '' }));
  };

  const nextPageHandler = () => {
    setHasPrevPage(true);
    setHasNextPage(false);

    setVariables((v) => {
      delete v.beforeCursor;
      delete v.last;
      return {
        ...v,
        afterCursor: pageInfo.endCursor,
        first: RECORDS_PER_PAGE,
      };
    });
  };
  const prevPageHandler = () => {
    setHasPrevPage(true);
    setHasNextPage(true);

    setVariables((v) => {
      delete v.afterCursor;
      delete v.first;
      return {
        ...v,
        beforeCursor: pageInfo.startCursor,
        last: RECORDS_PER_PAGE,
      };
    });
  };

  return (
    <Layout
      title={
        loading && !initialized ? 'loading...' : `${group.name} - Documents`
      }
    >
      <SearchHeader
        searchFunc={search}
        clearSearch={clearSearch}
        showUploadButton={false}
      />
      <Documents
        items={documents}
        totalCount={get(data, 'group.documents.totalCount', 0)}
        loading={loading}
        nextPageHandler={nextPageHandler}
        prevPageHandler={prevPageHandler}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage || !!pageInfo.hasNextPage}
        queryVariables={variables}
        setQueryVariables={setVariables}
      />
    </Layout>
  );
};

export default GroupDocuments;
