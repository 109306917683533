import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  margin: 0 -40px;
  padding: 0 40px;
  border-bottom: 1px solid #dbdbdb;

  @media ${breakpoint.md} {
    margin: 0 -15px;
    padding: 0 15px;
  }
`;

export const Header = styled.button`
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  svg {
    width: 23px;
    height: 15px;
    transition: transform 0.3s ease-out;
  }

  ${(props) =>
    props.toggled
      ? `
        svg {
          transform: rotate(180deg);
        }
      `
      : null}

  &:focus {
    outline: none;
  }
`;

export const Section = styled.section`
  padding-bottom: 10px;
`;
