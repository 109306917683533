import React from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { TRIGGER_PASSWORD_RESET } from '@graphQL/mutations';

import TextField from '@components/common/Form/TextField';
import Button, { VARIANT, SIZE } from '@common/Button';
import { createToast, TOAST_TYPES } from '@components/common/Toast';
import routes from '@routes';
import { Form, Row } from './style';

const ForgotPasswordForm = ({ history }) => {
  const client = useApolloClient();

  return (
    <Formik
      initialValues={{ email: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }

        return errors;
      }}
      onSubmit={async ({ email }, { setSubmitting }) => {
        try {
          const {
            data: { triggerPasswordReset },
          } = await client.mutate({
            mutation: TRIGGER_PASSWORD_RESET,
            variables: { input: { email } },
          });

          if (triggerPasswordReset.success) {
            createToast(
              'Reset password link has been sent. Please check your email.',
              TOAST_TYPES.SUCCESS,
            );
            history.push(routes.auth.signIn);
          }
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <TextField
            name="email"
            label="Email"
            placeholder="e.g. joe@Bloggs.co.uk"
          />

          <Row>
            <Button
              type="submit"
              text="Reset password"
              icon="arrow-right"
              variant={VARIANT.PRIMARY}
              size={SIZE.LARGE}
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </Row>
          <Row>
            <Button
              type="button"
              text="Back to login"
              icon="arrow-right-dark"
              variant={VARIANT.SECONDARY}
              size={SIZE.MEDIUM}
              onClick={() => history.push(routes.auth.signIn)}
            />
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(ForgotPasswordForm);
