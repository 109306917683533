import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation($input: CreateUserMutationInput!) {
    createUser(input: $input) {
      user {
        firstName
        lastName
        email
        userType {
          name
        }
      }
      errors {
        field
        messages
      }
      clientMutationId
    }
  }
`;
