import React from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_ACCOUNT_NAME } from '@graphQL/mutations';
import { USER } from '@graphQL/queries';

import { createToast, TOAST_TYPES } from '@common/Toast';
import TextField from '@components/common/Form/TextField';
import Button, { VARIANT, SIZE } from '@common/Button';
import { Form } from '../../style';

const ChangeAccountName = ({ history }) => {
  const [updateAccountName] = useMutation(UPDATE_ACCOUNT_NAME);
  const {
    data: { user },
  } = useQuery(USER);

  return (
    <Formik
      initialValues={{ firstName: user.firstName, lastName: user.lastName }}
      validate={(values) => {
        const errors = {};
        if (!values.firstName) {
          errors.firstName = 'Required';
        }
        if (!values.lastName) {
          errors.lastName = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await updateAccountName({
            variables: { input: { ...values } },
          });

          createToast(
            'Your account name was successfully updated',
            TOAST_TYPES.SUCCESS,
          );
          setSubmitting(false);
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <TextField name="firstName" label="First name" type="text" />
          <TextField name="lastName" type="text" label="Last name" />
          <Button
            type="submit"
            text="Save changes"
            icon="disk"
            iconPosition="prefix"
            variant={VARIANT.PRIMARY}
            size={SIZE.LARGE}
            loading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(ChangeAccountName);
