import React from 'react';
import PropTypes from 'prop-types';
import { Circle } from './style';

const CircularProgressBar = ({ size, strokeWidth, percentage }) => {
  const radius = (size - strokeWidth) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <Circle width={size} height={size} viewBox={viewBox}>
      <circle
        className="circle-background"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className="circle-progress"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
    </Circle>
  );
};

CircularProgressBar.defaultProps = {
  size: 47,
  strokeWidth: 2,
};

CircularProgressBar.propTypes = {
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  percentage: PropTypes.number.isRequired,
};

export default CircularProgressBar;
