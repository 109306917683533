import { gql } from '@apollo/client';

export const UPDATE_USER_ACCOUNT_TYPE = gql`
  mutation($input: UpdateAccountUserTypeMutationInput!) {
    updateAccountUserType(input: $input) {
      user {
        firstName
        lastName
      }
    }
  }
`;
