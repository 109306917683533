import React from 'react';
import PropTypes from 'prop-types';
import Card from '@common/Card';
import { Wrapper } from './style';

const AuthLayout = ({ children }) => (
  <Wrapper>
    <Card>{children}</Card>
  </Wrapper>
);

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthLayout;
