import React from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { getItem, setItem, removeItem, KEYS } from '@storage';
import { useMutation } from '@apollo/client';
import { LOGIN_TWO_FACTOR } from '@graphQL/mutations';
import routes from '@routes';
import jwtDecode from 'jwt-decode';

import { createToast, TOAST_TYPES } from '@common/Toast';
import TextField from '@components/common/Form/TextField';
import Button, { VARIANT, SIZE } from '@common/Button';
import { Form } from './style';

const TokenForm = ({ history }) => {
  const [login2Factor] = useMutation(LOGIN_TWO_FACTOR);

  const token = getItem(KEYS.TOKEN_2_FA);

  return (
    <Formik
      initialValues={{ code: '', token }}
      validate={(values) => {
        const errors = {};
        if (!values.code) {
          errors.code = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          const {
            data: { loginTwoFactor },
          } = await login2Factor({
            variables: { input: { ...values } },
          });

          if (loginTwoFactor.success) {
            const {
              accessToken,
              refreshToken,
            } = loginTwoFactor.authenticationToken;
            const decodedToken = jwtDecode(accessToken);

            setItem(KEYS.AUTH_TOKEN, accessToken);
            setItem(KEYS.REFRESH_TOKEN, refreshToken);
            setItem(KEYS.TOKEN_EXPIRATION, decodedToken.exp * 1000);

            removeItem(KEYS.TOKEN_2_FA);
            removeItem(KEYS.TOKEN_2_FA_SETUP);
            setSubmitting(false);
            history.push(routes.base);
          } else {
            setFieldError('code', 'Invalid code entered');
            setSubmitting(false);
          }
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          setSubmitting(false);
          history.push(routes.auth.signIn);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <TextField
            name="code"
            type="password"
            label="Please enter your authentication code"
            placeholder="*********"
            autoFocus
            toggleable
          />
          <Button
            type="submit"
            text="Submit"
            icon="arrow-right"
            variant={VARIANT.PRIMARY}
            size={SIZE.LARGE}
            loading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(TokenForm);
