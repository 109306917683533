import React from 'react';
import ToggleableSection from '@common/ToggleableSection';
import Layout from '../layout';

import PasswordChange from './components/PasswordChange';
import EmailChange from './components/ChangeEmail';
import ChangeAccountName from './components/ChangeAccountName';

const Settings = () => {
  return (
    <Layout title="Settings">
      <ToggleableSection title="Change password">
        <PasswordChange />
      </ToggleableSection>
      <ToggleableSection title="Change email">
        <EmailChange />
      </ToggleableSection>
      <ToggleableSection title="Change name">
        <ChangeAccountName />
      </ToggleableSection>
    </Layout>
  );
};

export default Settings;
