import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CLIENT, USERS, USER } from '@graphQL/queries';
import Text from '@common/Text';
import Loader from '@common/Loader';
import { createToast, TOAST_TYPES } from '@components/common/Toast';

import Layout from '@components/authenticated/layout';
import NewUserForm from '@components/authenticated/Users/components/NewUser';
import UserForm from '@components/authenticated/Users/components/UserForm';

import { Section, Title } from './style';

const Users = () => {
  const [activeUsers, setActiveUsers] = useState([]);
  const [suspendedUsers, setSuspendedUsers] = useState([]);
  const { data: userQuery } = useQuery(USER);
  const { data: usersData, loading: usersLoading, error: userError } = useQuery(
    USERS,
    {
      fetchPolicy: 'network-only',
    },
  );
  const {
    data: clientData,
    loading: clientLoading,
    error: clientError,
  } = useQuery(CLIENT, {
    fetchPolicy: 'network-only',
  });
  const currentUser = userQuery?.user;

  if (clientError) {
    createToast(clientError.message, TOAST_TYPES.ERROR);
  }
  if (userError) {
    createToast(userError.message, TOAST_TYPES.ERROR);
  }

  const client = clientData?.client || {};

  useEffect(() => {
    const users = usersData?.users.edges || [];
    const active = users.filter(({ node: user }) => {
      if (currentUser) {
        return user.id !== currentUser.id && user.isEnabled;
      }
      return user.isEnabled;
    });
    setActiveUsers(active);
    setSuspendedUsers(users.filter(({ node: user }) => !user.isEnabled));
  }, [usersData, currentUser]);

  if (usersLoading || clientLoading) {
    return <Loader />;
  }
  return (
    <Layout title="Users">
      <Section>
        <Text type="H2">{client.name || ''}</Text>
      </Section>

      <Section>
        <Title>
          <Text type="H3">Active</Text>
        </Title>

        {activeUsers.map(({ node: user }, index) => (
          <UserForm
            key={user.id}
            user={user}
            suspended={false}
            showLabels={index === 0}
          />
        ))}
      </Section>
      <Section>
        <Title>
          <Text type="H3">Suspended</Text>
        </Title>
        {suspendedUsers.length === 0 ? (
          <Text>
            No suspended users. You can deactivate user accounts using the
            &quot;Disable&quot; buttons above.
          </Text>
        ) : (
          suspendedUsers.map(({ node: user }, index) => (
            <UserForm
              key={user.id}
              user={user}
              suspended
              showLabels={index === 0}
            />
          ))
        )}
      </Section>
      <Section>
        <Title>
          <Text type="H3">Invite</Text>
        </Title>
        <NewUserForm />
      </Section>
    </Layout>
  );
};

export default Users;
