import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${breakpoint.md} {
    position: fixed;
    right: 16px;
    top: 21px;
    z-index: 15;

    > h5 {
      display: none;
    }
  }
`;

export const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  color: #fff;
  border: none;
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;

  &:focus {
    outline: none;
  }

  img {
    display: block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  @media ${breakpoint.md} {
    margin-top: 0;
  }
`;
