import styled from '@emotion/styled';
import { Form as FormikForm } from 'formik';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  margin: -30px -40px 0;
  background: linear-gradient(
    360deg,
    rgba(42, 52, 79, 0.1) 0%,
    rgba(0, 90, 212, 0) 94.59%
  );
  padding: 30px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 150px;
  border-bottom: 1px solid #dbdbdb;

  @media ${breakpoint.md} {
    margin: -15px -15px -25px;
    padding: 15px 15px 40px;
    flex-wrap: wrap;
    min-height: unset;

    > button {
      width: 100%;
    }
  }
`;

export const Form = styled(FormikForm)`
  width: 70%;
  max-width: 430px;

  @media ${breakpoint.md} {
    width: 100%;
    margin-bottom: 10px;
  }
`;
