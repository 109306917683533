import { gql } from '@apollo/client';

export const VERIFY_2FA_TOKEN = gql`
  mutation($input: VerifyTwoFactorConfigMutationInput!) {
    verifyTwoFactorConfig(input: $input) {
      authenticationToken {
        accessToken
        refreshToken
      }
      success
    }
  }
`;
