import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import map from 'lodash/map';
import { useQuery, useApolloClient } from '@apollo/client';
import { Formik, Form } from 'formik';
import moment from 'moment';
import Select from '@common/Form/Select';
import { DOCUMENT, DOCUMENT_TYPES } from '@graphQL/queries';
import { UPDATE_DOCUMENT } from '@graphQL/mutations';
import Loader from '@common/Loader';
import { createToast, TOAST_TYPES } from '@common/Toast';
import routes from '@routes';
import Button from '@common/Button';
import Text from '@common/Text';
import { download } from '@helpers';
import EditInPlace from './components/EditInPlace';
import Layout from '../layout';
import {
  Breadcrumbs,
  BreadcrumbsItem,
  Buttons,
  Column,
  Details,
  Section,
  SectionTitle,
  Top,
} from './style';

const DocumentDetails = () => {
  const client = useApolloClient();
  const { docId } = useParams();
  const [dtValues, setDtValues] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const { loading, error, data } = useQuery(DOCUMENT, {
    variables: {
      documentId: docId,
    },
    fetchPolicy: 'network-only',
  });

  const documentTypesQuery = useQuery(DOCUMENT_TYPES);

  useEffect(() => {
    const dt = get(documentTypesQuery, 'data.documentTypes', []);
    if (dt.length) {
      setDtValues(
        map(dt, (d) => ({
          value: d.id,
          label: d.name,
        })),
      );
    }
  }, [documentTypesQuery]);

  if (error) {
    createToast(error.message, TOAST_TYPES.ERROR);
  }
  const document = get(data, 'document', {});

  return (
    <Layout title="Your Documents">
      {loading ? (
        <Loader absolute />
      ) : (
        <Formik
          initialValues={{
            document: document.id,
            name: document.name,
            documentType: document.documentType ? document.documentType.id : '',
          }}
          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            try {
              client.mutate({
                mutation: UPDATE_DOCUMENT,
                variables: {
                  input: {
                    ...values,
                  },
                },
                refetchQueries: [
                  {
                    query: DOCUMENT,
                    variables: {
                      documentId: docId,
                    },
                  },
                ],
              });

              setSubmitting(false);
              createToast('Document updated successfully', TOAST_TYPES.SUCCESS);
              setEditMode(false);
            } catch (err) {
              createToast(err.message, TOAST_TYPES.ERROR);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Top>
                <Breadcrumbs>
                  <BreadcrumbsItem to={routes.home}>Home</BreadcrumbsItem>
                  <img src="images/icons/arrow-right-primary.svg" alt="" />
                  <span>{document.name || 'unnamed'}</span>
                </Breadcrumbs>
                <Button
                  text="Save changes"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Top>

              <EditInPlace
                fieldName="name"
                value={document.name}
                editMode={editMode}
                setEditMode={setEditMode}
              />

              <Details>
                <Column>
                  <Section>
                    <SectionTitle>
                      <Text type="H4">Document ID</Text>
                    </SectionTitle>
                    <Text type="Text">{document.documentId || ''}</Text>
                  </Section>

                  <Section>
                    <SectionTitle>
                      <Text type="H4">Contract type</Text>
                    </SectionTitle>

                    <Select
                      name="documentType"
                      options={dtValues}
                      width="200px"
                    />
                  </Section>

                  <Section>
                    <SectionTitle>
                      <Text type="H4">Date uploaded</Text>
                    </SectionTitle>
                    <Text type="Text">
                      {moment(document.createdAt).format('DD/MM/YYYY') || ''}
                    </Text>
                  </Section>

                  <Section>
                    <SectionTitle>
                      <Text type="H4">Date sent to Robin</Text>
                    </SectionTitle>
                    <Text type="Text">
                      {document.sentForReviewDate
                        ? moment(document.sentForReviewDate).format(
                            'DD/MM/YYYY',
                          )
                        : 'Document is yet to be sent for review.'}
                    </Text>
                  </Section>
                </Column>

                <Column>
                  <Section>
                    <SectionTitle>
                      <Text type="H4">Playbook</Text>
                    </SectionTitle>
                    <Text type="Text">
                      {document.playbook
                        ? document.playbook.name
                        : 'No playbook is associated with this document.'}
                    </Text>
                  </Section>

                  <Section>
                    <SectionTitle>
                      <Text type="H4">Comments</Text>
                    </SectionTitle>
                    <Text type="Text">
                      {document.uploadComment
                        ? document.uploadComment
                        : 'None.'}
                    </Text>
                  </Section>
                </Column>
              </Details>
              <Buttons>
                <Button
                  onClick={() => download(client, document, 'ORIGINAL_FILE')}
                  text="Download original"
                  icon="download"
                  iconPosition="prefix"
                />

                {document.status === 'REVIEWED' ? (
                  <Button
                    onClick={() => download(client, document)}
                    text="Download Revised"
                    icon="download"
                    iconPosition="prefix"
                  />
                ) : null}
              </Buttons>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export default DocumentDetails;
