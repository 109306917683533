import React, { useState } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { GROUPS } from '@graphQL/queries';
import { createToast, TOAST_TYPES } from '@components/common/Toast';
import Layout from '@components/authenticated/layout';
import { Formik } from 'formik';
import Button from '@common/Button';
import TextField from '@common/Form/TextField';
import Loader from '@common/Loader';
import Modal from '@common/Modal';
import Group from '@components/authenticated/Groups/components/Group';
import NewGroupCreator from '@components/authenticated/Groups/components/NewGroupCreator';
import { Header, Content, Form } from '@components/authenticated/Groups/style';

const Groups = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [editingGroup, setEditingGroup] = useState({});
  const { data, loading, error: queryError, refetch: searchGroups } = useQuery(
    GROUPS,
    {
      fetchPolicy: 'network-only',
    },
  );
  let debounce;

  const groups = get(data, 'groups.edges', []);

  if (queryError) {
    createToast(queryError.message, TOAST_TYPES.ERROR);
  }

  const closeCreationModal = () => {
    setModalVisible(false);
    setEditingGroup({});
  };

  const searchGroupsHandler = (value, submit) => {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      if (value.length >= 3 || !value.length) {
        submit();
      }
    }, 300);
  };

  const editButtonHandler = (group) => {
    setEditingGroup(group);
    setModalVisible(true);
  };

  return (
    <>
      <Layout title="Groups">
        <Header>
          <Formik
            initialValues={{ name: '' }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await searchGroups({ name: values.name });
              } catch (error) {
                createToast(error.message, TOAST_TYPES.ERROR);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ submitForm, values, resetForm }) => (
              <Form>
                <TextField
                  name="name"
                  placeholder="Search by group name"
                  icon="magnifier"
                  textSize="normal"
                  single
                  onChange={(ev) => {
                    const { value } = ev.currentTarget;
                    searchGroupsHandler(value, submitForm);
                  }}
                  onClear={() => {
                    resetForm();
                    searchGroupsHandler('', submitForm);
                  }}
                  withClearButton={!!values.name}
                />
              </Form>
            )}
          </Formik>
          <Button
            text="Create new group"
            icon="add-group"
            iconPosition="prefix"
            onClick={() => setModalVisible(true)}
          />
        </Header>
        <Content>
          {loading ? <Loader /> : null}

          {groups.map(({ node: group }) => (
            <Group group={group} key={group.id} edit={editButtonHandler} />
          ))}
        </Content>
      </Layout>
      {modalVisible ? (
        <Modal close={closeCreationModal}>
          <NewGroupCreator
            closeModal={closeCreationModal}
            group={editingGroup}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default Groups;
