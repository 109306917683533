import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Text from '@common/Text';
import { useField } from 'formik';
import { Wrapper, Label, Error } from './style';

const SelectField = ({ options, label, single, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <Wrapper withError={meta.touched && meta.error} single={single}>
      {label ? (
        <Label>
          <Text color={200}>{label}</Text>
        </Label>
      ) : null}
      <Select
        classNamePrefix="select"
        options={options}
        name={field.name}
        value={
          options ? options.find((option) => option.value === field.value) : ''
        }
        onChange={(option) => helpers.setValue(option.value)}
        onBlur={field.onBlur}
      />
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </Wrapper>
  );
};

SelectField.defaultProps = {
  label: null,
  single: false,
};

SelectField.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  single: PropTypes.bool,
};

export default SelectField;
