import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { getItem, setItem, removeItem, KEYS } from '@storage';
import { useMutation } from '@apollo/client';
import QRCode from 'qrcode.react';
import { SETUP_2FA_CONFIG, VERIFY_2FA_CONFIG } from '@graphQL/mutations';
import routes from '@routes';
import jwtDecode from 'jwt-decode';

import { createToast, TOAST_TYPES } from '@common/Toast';
import TextField from '@components/common/Form/TextField';
import Button, { VARIANT, SIZE } from '@common/Button';
import Text from '@common/Text';
import { Form, QRRow } from './style';

const GOOGLE_AUTH_ANDROID =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB';
const GOOGLE_AUTH_APPLE =
  'https://apps.apple.com/gb/app/google-authenticator/id388497605';

const TokenForm = ({ history }) => {
  const [setup2Factor] = useMutation(SETUP_2FA_CONFIG);
  const [verify2FactorConfig] = useMutation(VERIFY_2FA_CONFIG);
  const [qrURI, setQrURI] = useState(null);

  const token = getItem(KEYS.TOKEN_2_FA) || getItem(KEYS.TOKEN_2_FA_SETUP);

  useEffect(() => {
    const generateQR = async () => {
      try {
        const {
          data: { setupTwoFactorConfig },
        } = await setup2Factor({
          variables: { input: { token } },
        });
        setQrURI(setupTwoFactorConfig.provisioningUri);
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
    };

    generateQR();
  }, [token, setup2Factor]);

  return (
    <Formik
      initialValues={{ code: '', token }}
      validate={(values) => {
        const errors = {};
        if (!values.code) {
          errors.code = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          const {
            data: { verifyTwoFactorConfig },
          } = await verify2FactorConfig({
            variables: { input: { ...values } },
          });

          if (verifyTwoFactorConfig.success) {
            const {
              accessToken,
              refreshToken,
            } = verifyTwoFactorConfig.authenticationToken;
            const decodedToken = jwtDecode(accessToken);

            setItem(KEYS.AUTH_TOKEN, accessToken);
            setItem(KEYS.REFRESH_TOKEN, refreshToken);
            setItem(KEYS.TOKEN_EXPIRATION, decodedToken.exp * 1000);

            removeItem(KEYS.TOKEN_2_FA);
            removeItem(KEYS.TOKEN_2_FA_SETUP);
            setSubmitting(false);
            history.push(routes.base);
          } else {
            setFieldError('code', 'Invalid code entered');
            setSubmitting(false);
          }
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          setSubmitting(false);
          history.push(routes.auth.signIn);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          {qrURI ? (
            <>
              <Text type="H4" center>
                Scan the QR code below with your app
              </Text>
              <br />
              <Text center>
                You will need to install an Authenticator app on your phone, if
                you do not already have one. We recommend using Google
                Authenticator (
                <a
                  href={GOOGLE_AUTH_ANDROID}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Android
                </a>
                , &nbsp;
                <a
                  href={GOOGLE_AUTH_APPLE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apple
                </a>
                ).
              </Text>
              <QRRow>
                <QRCode value={qrURI} size={220} />
              </QRRow>
            </>
          ) : null}

          <Text center>
            Using the app, please scan the QR code and enter the 6-digit code
            that is displayed.
          </Text>
          <br />
          <br />

          <TextField
            name="code"
            type="password"
            label="Authentication code"
            placeholder="*********"
            autoFocus
            toggleable
          />
          <Button
            type="submit"
            text="Submit"
            icon="arrow-right"
            variant={VARIANT.PRIMARY}
            size={SIZE.LARGE}
            loading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(TokenForm);
