import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;

export const Main = styled.div`
  flex: 1;
  padding-right: 16px;
`;

export const Header = styled.div`
  display: flex;
  height: 103px;
  align-items: center;
  color: #fff;
  justify-content: space-between;
`;

export const Section = styled.div`
  margin-bottom: 50px;
`;

export const Title = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.div`
  margin-bottom: 10px;
`;
