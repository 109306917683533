import styled from '@emotion/styled';
import { Form as FormikForm } from 'formik';
import { breakpoint } from '@styles/breakpoints';

export const Form = styled(FormikForm)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;

  button {
    @media ${breakpoint.sm} {
      width: 100%;
    }
    & + button {
      margin-left: 10px;

      @media ${breakpoint.sm} {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }
  }
`;

export const Field = styled.div`
  flex: ${(props) => props.flex || 1};
  margin-right: 10px;

  @media ${breakpoint.sm} {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 10px;
  }
`;

export const Column = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  flex-wrap: wrap;

  @media ${breakpoint.sm} {
    width: 100%;
    flex: 0 0 100%;
  }
`;
