import styled from '@emotion/styled';
import { getPalette } from '@styles/helpers';

export const Bar = styled.div`
  position: relative;
  width: 100%;
  height: 5px;
  background-color: #dbdbdb;
  border-radius: 100px;
  overflow: hidden;
  margin-top: 5px;
`;

export const Meter = styled.div`
  transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
  width: ${(props) => {
    if (props.value.length > 4 && !props.strength) {
      return 5;
    }
    return props.strength * 25;
  }}%;
  height: 100%;
  background-color: ${(props) => {
    const c = getPalette(props);

    switch (props.strength) {
      case 0:
        return 'red';
      case 1:
        return c.red;
      case 2:
        return c.amber;
      case 3:
        return '#94d69c';
      case 4:
        return c.green;
      default:
        return c.red;
    }
  }};
`;
