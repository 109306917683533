import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import uniqueId from 'lodash/uniqueId';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from '@common/ProtectedRoute';
import AuthRoute from '@common/AuthRoute';
import GlobalStyle from '@styles/globalStyles';
import theme from '@styles/theme';
import routes from '@routes';
import ToastWrapper from '@common/Toast';

import SignIn from '@components/auth/SignIn';
import SetupAccount from '@components/auth/SetupAccount';
import TokenGeneration from '@components/auth/2faToken';
import ForgotPassword from '@components/auth/ForgotPassword';
import ResetPassword from '@components/auth/ResetPassword';
import Base from '@components/Base';
import Home from '@components/authenticated/Home';
import Document from '@components/authenticated/Document';
import Upload from '@components/authenticated/Upload';
import Users from '@components/authenticated/Users';
import Groups from '@components/authenticated/Groups';
import Settings from '@components/authenticated/Settings';
import UserSettings from '@components/authenticated/UserSettings';
import GroupDocuments from '@components/authenticated/GroupDocuments';
import NotFound from '@components/NotFound';

const App = () => {
  const [uuid, setUuid] = useState(uniqueId('token_'));

  useEffect(() => {
    window.addEventListener('robin.refresh', () => setUuid(uniqueId('token_')));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Switch>
          <ProtectedRoute exact path={routes.base} component={Base} />
          <ProtectedRoute
            exact
            path={routes.document()}
            component={(props) => <Document {...props} uuid={uuid} />}
          />
          <ProtectedRoute
            exact
            path={routes.home}
            component={(props) => <Home {...props} uuid={uuid} />}
          />
          <ProtectedRoute
            exact
            path={routes.upload}
            component={(props) => <Upload {...props} uuid={uuid} />}
          />
          <ProtectedRoute
            exact
            path={routes.userSettings()}
            component={(props) => <UserSettings {...props} uuid={uuid} />}
          />
          <ProtectedRoute
            exact
            path={routes.users}
            component={(props) => <Users {...props} uuid={uuid} />}
          />
          <ProtectedRoute
            exact
            path={routes.groupDocuments()}
            component={(props) => <GroupDocuments {...props} uuid={uuid} />}
          />
          <ProtectedRoute
            exact
            path={routes.groups}
            component={(props) => <Groups {...props} uuid={uuid} />}
          />
          <ProtectedRoute
            exact
            path={routes.settings}
            component={(props) => <Settings {...props} uuid={uuid} />}
          />
          <AuthRoute exact path={routes.auth.signIn} component={SignIn} />
          <AuthRoute path={routes.auth.join} component={SetupAccount} />
          <AuthRoute
            exact
            path={routes.auth.login_2fa}
            component={TokenGeneration}
          />
          <AuthRoute
            exact
            path={routes.auth.setup_2fa}
            component={TokenGeneration}
          />
          <AuthRoute
            exact
            path={routes.auth.forgotPassword}
            component={ForgotPassword}
          />
          <AuthRoute
            exact
            path={routes.auth.resetPassword}
            component={ResetPassword}
          />
          <Route from="*" component={NotFound} />
        </Switch>
        <ToastWrapper />
      </Router>
    </ThemeProvider>
  );
};

export default App;
