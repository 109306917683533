import jwtDecode from 'jwt-decode';
import { getItem, setItem, KEYS, clear } from '@storage';
// eslint-disable-next-line import/no-cycle
import {
  createToast,
  TOAST_TYPES,
  SESSION_EXPIRED_MESSAGE,
} from '@common/Toast';

let renewingSession = false;

export const renewSession = async (renewAccessTokenCall, client) => {
  if (!renewingSession) {
    renewingSession = true;
    try {
      const refreshToken = getItem(KEYS.REFRESH_TOKEN);
      const mutation = await renewAccessTokenCall({
        variables: { input: { refreshToken } },
      });

      const {
        data: {
          renewAccessToken: { token },
        },
      } = mutation;

      const decodedToken = jwtDecode(token);
      setItem(KEYS.AUTH_TOKEN, token);
      setItem(KEYS.TOKEN_EXPIRATION, decodedToken.exp * 1000);
      const event = new Event('robin.refresh');
      window.dispatchEvent(event);

      return mutation;
    } catch (error) {
      createToast(SESSION_EXPIRED_MESSAGE, TOAST_TYPES.ERROR);
      clear();
    } finally {
      renewingSession = false;
    }
  }
  return null;
};
