import styled from '@emotion/styled';
import { getColors, getPalette } from '@styles/helpers';

export const Label = styled.div`
  display: block;
  margin-bottom: 7px;
`;

export const Wrapper = styled.div`
  color: ${(props) => (props.withError ? getColors(props).error : '#585757')};
  width: ${(props) => (props.width ? props.width : '100%')};
  margin-bottom: ${(props) => (props.single ? 0 : '30px')};
  position: relative;

  .select__control {
    height: 48px;
    border-color: ${(props) =>
      props.withError ? getColors(props).error : '#DBDBDB'};
    transition: border-color 0.3s ease-in-out;

    &:hover {
      border-color: #b7b7b7;
    }

    &--is-focused {
      border-color: ${(props) => getPalette(props).base};
      box-shadow: none;
      outline: none;

      &:hover {
        border-color: ${(props) => getPalette(props).base};
      }
    }
  }
  .select__indicator-separator {
    display: none;
  }

  .select__dropdown-indicator {
    background: url('images/icons/dropdown-arrow.svg') no-repeat 50% 50% / 10px;
    margin-right: 15px;
    svg {
      display: none;
    }
  }

  .select__option {
    font-size: 15px;
  }

  ${(props) =>
    props.withError
      ? `
    .select__control {
      &:hover {
        border-color: ${getColors(props).error};
      }
    }

    ${Label} {
      span {
        color: ${getColors(props).error};
      }
    }
  `
      : null}
`;

export const Error = styled.div`
  color: ${(props) => getColors(props).error};
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  bottom: -24px;
`;
