import { gql } from '@apollo/client';

export const CLIENT = gql`
  query {
    client {
      name
      users {
        edges {
          node {
            id
            firstName
            lastName
            userType {
              id
              name
            }
            isEnabled
          }
        }
      }
    }
  }
`;
