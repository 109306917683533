import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
`;

export const Icon = styled.button`
  padding: 0;
  cursor: pointer;
  img {
    display: block;
  }
`;
