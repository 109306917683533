import styled from '@emotion/styled';
import { getBackgrounds } from '@styles/helpers';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  background-color: ${(props) => getBackgrounds(props).light.secondary};
  border-radius: 10px;
  padding: 30px 30px 140px 30px;
  box-shadow: 0 0 124px rgba(0, 0, 0, 0.6);
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media ${breakpoint.md} {
    padding: 15px 15px 30px;
  }
`;

export const Logo = styled.img`
  display: block;
  margin-bottom: 90px;
  margin-top: 45px;

  @media ${breakpoint.nd} {
    margin: 20px 0 30px;
  }
`;
