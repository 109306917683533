import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import { USER } from '@graphQL/queries';

import Sidebar from '@common/Sidebar';
import Text from '@common/Text';
import UserBox from '@common/UserBox';
import { createToast, TOAST_TYPES } from '@common/Toast';
import Loader from '@common/Loader';

import { Wrapper, Main, Header, Surface } from './style';

const AuthenticatedLayout = ({ children, title, surfacePadding }) => {
  const history = useHistory();
  const { loading, error, data } = useQuery(USER);

  if (error) {
    createToast(error.message, TOAST_TYPES.ERROR);
  }

  if (loading) return <Loader size={24} relative />;

  const user = get(data, 'user', {});
  const areGroupsEnabled = get(user, 'client.areGroupsEnabled', false);

  return (
    <Wrapper>
      <Sidebar areGroupsEnabled={areGroupsEnabled} />
      <Main>
        <Header>
          <Text type="H1" color="white">
            {title}
          </Text>
          <UserBox history={history} username={user.firstName || ''} />
        </Header>
        <Surface padding={surfacePadding}>{children}</Surface>
      </Main>
    </Wrapper>
  );
};

AuthenticatedLayout.defaultProps = {
  surfacePadding: null,
};

AuthenticatedLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  surfacePadding: PropTypes.string,
};

export default AuthenticatedLayout;
