const prefix = 'robin';

export const KEYS = {
  TOKEN_2_FA: 'TOKEN_2_FA',
  TOKEN_2_FA_SETUP: 'TOKEN_2_FA_SETUP',
  AUTH_TOKEN: 'AUTH_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  TOKEN_EXPIRATION: 'TOKEN_EXPIRATION',
  RENEW_TOKEN: 'RENEW_TOKEN',
};

export const getItem = (item) => localStorage.getItem(`${prefix}.${item}`);

export const setItem = (item, value) =>
  localStorage.setItem(`${prefix}.${item}`, value);

export const clear = () => {
  const lsKeys = Object.keys(localStorage);
  lsKeys.forEach((key) => {
    if (key.indexOf(prefix) !== -1) {
      localStorage.removeItem(key);
    }
  });
};

export const removeItem = (item) =>
  localStorage.removeItem(`${prefix}.${item}`);
