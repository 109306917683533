import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import get from 'lodash/get';
import fetch from 'cross-fetch';
import { clear, getItem, KEYS } from '@storage';
import { RENEW_ACCESS_TOKEN } from '@graphQL/mutations';
import { renewSession } from '@utils/session'; // eslint-disable-line
// eslint-disable-next-line
import {
  createToast,
  TOAST_TYPES,
  SESSION_EXPIRED_MESSAGE,
} from '@common/Toast';

const renewSessionObserver = (promise) =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err),
    );
    return subscriber; // this line can removed, as per next comment
  });

let renewingSession = false;

const renewSessionMutation = async (variables) => {
  if (!renewingSession) {
    renewingSession = true;

    try {
      const mutation = await client.mutate({
        mutation: RENEW_ACCESS_TOKEN,
        ...variables,
      });

      return mutation;
    } catch (error) {
      createToast(SESSION_EXPIRED_MESSAGE, TOAST_TYPES.ERROR);
      clear();
    } finally {
      renewingSession = false;
    }
  }

  return null;
};

export const mapFormErrors = (resposne, model) => {
  const apiErrors = get(resposne, `${model}.errors`);

  if (apiErrors) {
    const errors = {};
    apiErrors.forEach((err) => {
      errors[err.field] = err.messages[0]; // eslint-disable-line prefer-destructuring
    });
    return errors;
  }

  return null;
};

const httpLink = () =>
  createHttpLink({
    uri: process.env.REACT_APP_API_URL,
    fetch,
  });

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      if (
        graphQLErrors[0].message === 'Signature has expired' ||
        graphQLErrors[0].message === 'Error decoding signature'
      ) {
        return renewSessionObserver(
          renewSession(renewSessionMutation, client),
        ).flatMap(() => forward(operation));
      }
      graphQLErrors.map(({ message, locations, path }) => {
        // eslint-disable-next-line no-console
        return console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }

    if (networkError) console.log(`[Network error]: ${networkError}`); // eslint-disable-line no-console

    return null;
  },
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getItem(KEYS.AUTH_TOKEN);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

const loggerLink = new ApolloLink((operation, forward) => {
  console.log(`GraphQL Request: ${operation.operationName}`, { operation }); // eslint-disable-line no-console
  operation.setContext({ start: new Date() });
  return forward(operation).map((response) => {
    const responseTime = new Date() - operation.getContext().start;
    console.log(`GraphQL Response took: ${responseTime}`); // eslint-disable-line no-console
    return response;
  });
});

export const cache = new InMemoryCache();

export const clientLink = ApolloLink.from([
  loggerLink,
  errorLink,
  authLink,
  httpLink(),
]);

const client = new ApolloClient({
  link: clientLink,
  cache,
});

export default client;
