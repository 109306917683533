import styled from '@emotion/styled';
import { getColors } from '@styles/helpers';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  position: relative;
  min-height: 200px;
  overflow: auto;
  width: calc(100% + 40px);
  margin: -26px -20px 0;

  @media ${breakpoint.md} {
    margin: -26px 0 0 0;
    width: 100%;
  }
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;

  @media ${breakpoint.md} {
    margin: 0;
    width: 100%;
  }
`;

export const Head = styled.thead``;

export const Body = styled.tbody``;

export const NoItems = styled.div`
  text-align: center;
  padding: 50px 0;
`;

export const Cell = styled.td`
  font-size: 14px;
  line-height: 18px;

  color: ${(props) => {
    return props.active
      ? getColors(props).text.primary
      : getColors(props).text[300];
  }};

  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  position: relative;
  padding-left: 30px;
  height: 48px;
  vertical-align: middle;

  @media ${breakpoint.md} {
    padding-left: 10px;
  }

  &:first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-of-type {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const Spacer = styled.td`
  height: 5px;
`;

export const Row = styled.tr`
  cursor: pointer;

  &:hover {
    ${Cell} {
      color: ${(props) => getColors(props).text[100]};
      background-color: rgba(0, 90, 212, 0.1);

      /* span:before {
        opacity: 0.29;
      } */
    }
  }
`;

export const NextPage = styled.button`
  background-image: url('images/icons/next.svg');
  margin-left: 15px;
`;

export const PrevPage = styled.button`
  background-image: url('images/icons/prev.svg');
`;

export const PageButtons = styled.div`
  display: flex;
`;

export const Footer = styled.div`
  border-top: 1px #dbdbdb solid;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-top: 24px;

  button {
    text-indent: -9999px;
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }
`;
