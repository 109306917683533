import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import { SUBMIT_DOCUMENT_TO_REVIEW } from '@graphQL/mutations';
import { PLAYBOOKS } from '@graphQL/queries';
import { createToast, TOAST_TYPES } from '@common/Toast';
import Text from '@common/Text';
import TextField from '@components/common/Form/TextField';
import Button, { SIZE } from '@common/Button';
import Checkbox from '@components/common/Form/Checkbox';
import SelectField from '@components/common/Form/Select';
import Loader from '@common/Loader';
import { Form, Row, Header } from './style';

const ReviewModal = ({ document, close }) => {
  const client = useApolloClient();
  const [playbooks, setPlaybooks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlaybooks = async () => {
      try {
        const {
          data: {
            playbooks: { edges: playbooksArray },
          },
        } = await client.query({
          query: PLAYBOOKS,
        });

        setPlaybooks(
          playbooksArray.map(({ node }) => ({
            value: node.id,
            label: node.name,
          })),
        );
        setLoading(false);
      } catch (error) {
        console.log(error); // eslint-disable-line no-console
      }
    };

    fetchPlaybooks();
  }, [client]);

  if (loading) {
    return <Loader relative />;
  }

  return (
    <Formik
      initialValues={{
        name: document.name,
        playbook: document.playbook ? document.playbook.id : '',
        uploadComment: document.uploadComment,
        isUrgent: false,
        document: document.id,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await client.mutate({
            mutation: SUBMIT_DOCUMENT_TO_REVIEW,
            variables: {
              input: {
                ...values,
              },
            },
          });

          createToast('Document sent to review', TOAST_TYPES.SUCCESS);
          setSubmitting(false);
          close();
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Header>
            <Text type="H3" center>
              Send to Robin
            </Text>
          </Header>

          <TextField name="name" label="Document name" />
          <SelectField name="playbook" options={playbooks} label="Playbook" />
          <TextField name="uploadComment" label="Comments" textarea />

          <Row>
            <Button
              type="submit"
              text="Send"
              icon="arrow-right"
              size={SIZE.LARGE}
            />
            <Checkbox
              name="isUrgent"
              label="Urgent"
              infoTip="Select if you need this document processed ASAP"
            />
          </Row>
        </Form>
      )}
    </Formik>
  );
};

ReviewModal.propTypes = {
  document: PropTypes.shape({
    createdAt: PropTypes.string,
    documentType: PropTypes.shape({ name: PropTypes.string }),
    group: PropTypes.shape({ name: PropTypes.string }),
    id: PropTypes.string,
    name: PropTypes.string,
    originalFilename: PropTypes.string,
    playbook: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
    status: PropTypes.string,
    uploadComment: PropTypes.string,
  }).isRequired,
  close: PropTypes.func.isRequired,
};

export default ReviewModal;
