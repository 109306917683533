import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UPDATE_EMAIL } from '@graphQL/mutations';
import { USER_BY_ID } from '@graphQL/queries';

import { createToast, TOAST_TYPES } from '@common/Toast';
import TextField from '@components/common/Form/TextField';
import Button, { VARIANT, SIZE } from '@common/Button';
import { Form } from '@components/authenticated/UserSettings/style';

const ChangeEmail = ({ user }) => {
  const [updateEmail] = useMutation(UPDATE_EMAIL);

  return (
    <Formik
      initialValues={{ email: '', emailConfirmation: '', user: user.id }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
          errors.email = 'Email address is invalid';
        }
        if (!values.emailConfirmation) {
          errors.emailConfirmation = 'Required';
        }

        if (
          values.email &&
          values.emailConfirmation &&
          values.email !== values.emailConfirmation
        ) {
          errors.email = "Email's don't match";
          errors.emailConfirmation = "Email's don't match";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await updateEmail({
            variables: { input: { email: values.email, user: values.user } },
            refetchQueries: [
              {
                query: USER_BY_ID,
                variables: {
                  id: user.id,
                },
              },
            ],
          });

          createToast(
            `${user.firstName} ${user.lastName}'s email was successfully updated`,
            TOAST_TYPES.SUCCESS,
          );
          resetForm();
          setSubmitting(false);
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <TextField name="email" label="New email" type="email" />
          <TextField
            name="emailConfirmation"
            type="email"
            label="Confirm new email"
          />
          <Button
            type="submit"
            text="Save changes"
            icon="disk"
            iconPosition="prefix"
            variant={VARIANT.PRIMARY}
            size={SIZE.LARGE}
            loading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

ChangeEmail.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    userType: PropTypes.shape({
      __typename: PropTypes.string,
      name: PropTypes.string,
    }),
    __typename: PropTypes.string,
  }).isRequired,
};

export default ChangeEmail;
