import React, { useState } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { isValidElementType } from 'react-is';
import { getItem, KEYS } from '@storage';

import routes from '@routes';

const AuthRoute = ({ component: RouteComponent, routeProps }) => {
  const [isAuthenticated, setAuthenticated] = useState(
    !!getItem(KEYS.AUTH_TOKEN),
  );

  if (isAuthenticated !== !!getItem(KEYS.AUTH_TOKEN)) {
    setAuthenticated(!!getItem(KEYS.AUTH_TOKEN));
  }

  return (
    <Route
      {...routeProps}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to={routes.base} />
        ) : (
          <RouteComponent {...props} />
        )
      }
    />
  );
};

AuthRoute.propTypes = {
  // eslint-disable-next-line
  component: (props, propName) => {
    if (props[propName] && !isValidElementType(props[propName])) {
      return new Error(
        `Invalid prop 'component' supplied to 'Route': the prop is not a valid React component`,
      );
    }
  },
};

export default withRouter(AuthRoute);
