import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { breakpoint } from '@styles/breakpoints';
import { getPalette } from '@styles/helpers';

export const Wrapper = styled.div`
  width: 170px;

  @media ${breakpoint.md} {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
  }
`;

export const Header = styled.div`
  height: 103px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  @media ${breakpoint.md} {
    margin-bottom: 0;
    height: 100%;
    background-color: ${(props) => getPalette(props).blue};
  }
`;

export const Navigation = styled.nav`
  @media ${breakpoint.md} {
    position: fixed;
    left: -70vw;
    top: 0;
    height: 100vh;
    width: 70vw;
    background: ${(props) => getPalette(props).base};
    transition: left 0.3s ease-in-out;
    z-index: 2;
    ${(props) => (props.visible ? `left: 0` : null)};
    padding-top: 30px;
  }
`;

export const NavigationLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  display: flex;
  height: 40px;
  padding-left: 30px;
  align-items: center;
  position: relative;
  transition: background-color 0.3s ease-in-out;

  &.active,
  &:hover {
    background-color: rgba(0, 90, 212, 0.2);

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
      background-color: #005ad4;
    }
  }

  img {
    margin-right: 15px;
    display: block;
    height: 18px;
  }

  @media ${breakpoint.md} {
    padding-left: 16px;
  }
`;

export const Logo = styled.img`
  display: block;
  height: 60px;
  margin-top: 10px;

  @media ${breakpoint.md} {
    height: 40px;
  }
`;

export const BurgerMenu = styled.button`
  display: none;
  width: 30px;
  height: 30px;
  background: transparent url('images/icons/menu.svg') no-repeat 50% 50% / 24px;
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 15px;

  @media ${breakpoint.md} {
    display: block;
  }
`;

export const MobileCover = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: ${(props) => getPalette(props).baseLight};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'all' : 'none')};
`;
