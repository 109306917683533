import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { getItem, KEYS } from '@storage';
import routes from '@routes';
import FormLogin from './components/FormLogin';
import FormConfig from './components/FormConfig';
import Layout from '../layout';

const SignIn = ({ history, location }) => {
  useEffect(() => {
    if (!getItem(KEYS.TOKEN_2_FA) && !getItem(KEYS.TOKEN_2_FA_SETUP)) {
      history.push(routes.auth.signIn);
    }
  }, [history]);

  return (
    <Layout>
      {location.pathname === routes.auth.setup_2fa ? (
        <FormConfig />
      ) : (
        <FormLogin />
      )}
    </Layout>
  );
};

export default withRouter(SignIn);
