import React from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { USER_BY_ID } from '@graphQL/queries';
import Text from '@common/Text';
import ToggleableSection from '@common/ToggleableSection';
import Loader from '@common/Loader';
import { createToast, TOAST_TYPES } from '@components/common/Toast';
import Layout from '@components/authenticated/layout';
import PasswordChange from '@components/authenticated/UserSettings/components/PasswordChange';
import EmailChange from '@components/authenticated/UserSettings/components/ChangeEmail';
import ChangeAccountName from '@components/authenticated/UserSettings/components/ChangeAccountName';
import ChangeUserAccountType from '@components/authenticated/UserSettings/components/ChangeAccountType';
import { Title, UserInfo } from '@components/authenticated/UserSettings/style';

const Settings = () => {
  const { userId } = useParams();
  const { data, loading, error } = useQuery(USER_BY_ID, {
    variables: {
      id: userId,
    },
    fetchPolicy: 'network-only',
  });

  const user = get(data, 'user', {});

  if (error) {
    createToast(error.message, TOAST_TYPES.ERROR);
  }

  return (
    <Layout title="Settings">
      {loading ? <Loader /> : null}
      <Title>
        <Text type="H2">
          {user.firstName || ''} {user.lastName}
        </Text>
        <UserInfo>
          <Text type="Text" color={200}>
            {user.userType ? user.userType.name : ''}
          </Text>
          <Text type="Text" color={300} size="small">
            {user.email || ''}
          </Text>
        </UserInfo>
      </Title>
      <ToggleableSection title="Change password">
        <PasswordChange user={user} />
      </ToggleableSection>
      <ToggleableSection title="Change email">
        <EmailChange user={user} />
      </ToggleableSection>
      <ToggleableSection title="Change name">
        <ChangeAccountName user={user} />
      </ToggleableSection>
      <ToggleableSection title="Change account type">
        <ChangeUserAccountType user={user} />
      </ToggleableSection>
    </Layout>
  );
};

export default Settings;
