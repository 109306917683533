import { gql } from '@apollo/client';

export const UPDATE_USER_STATUS = gql`
  mutation($input: UpdateAccountStatusMutationInput!) {
    updateAccountStatus(input: $input) {
      user {
        firstName
        lastName
        isEnabled
      }
    }
  }
`;
