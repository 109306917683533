const palette = {
  base: '#005AD4',
  baseLight: 'rgba(0, 90, 212, 0.2)',
  baseDarker: '#004fbb',
  blue: '#2A344F',
  blueLight: 'rgba(42, 52, 79, 0.2)',
  green: '#5AAF32',
  greenLight: 'rgba(115, 198, 76, 0.2)',
  red: '#E15555',
  redLight: 'rgba(225, 85, 85, 0.2)',
  redDarker: '#BD4A4A',
  amber: '#D0891E',
  amberLight: 'rgba(225, 169, 85, 0.2)',
  white: '#ffffff',
  gray: '#f1f1f1',
};

const fonts = {
  thin: 100,
  light: 300,
  normal: 400,
  bold: 700,
  black: 900,
};

export default {
  colors: {
    error: palette.red,
    text: {
      100: '#1C1C1C',
      200: '#585757',
      300: '#969696',
      400: '#E4E4E4',
      primary: palette.base,
      white: palette.white,
    },
  },
  buttons: {
    primary: palette.base,
    primaryHover: palette.baseDarker,
    secondary: palette.white,
    secondaryHover: palette.gray,
    danger: palette.red,
    dangerHover: palette.redDarker,
  },
  palette,
  fonts,
  backgrounds: {
    light: {
      primary: '#FFFFFF',
      secondary: '#F7F7F7',
    },
  },
  typography: {
    display: '64px',
    h1: '48px',
    h2: '36px',
    h3: '24px',
    h4: '20px',
    h5: '15px',
    h6: '13px',
    normal: '15px',
    big: '20px',
    small: '13px',
  },
  spacing: {
    bottom: '30px',
  },
};
