import React from 'react';
import Form from '@components/auth/ResetPassword/components/Form';
import Layout from '@components/auth/layout';

const ResetPassword = () => (
  <Layout>
    <Form />
  </Layout>
);

export default ResetPassword;
