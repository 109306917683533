import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';

import TextField from '@components/common/Form/TextField';
import Button, { VARIANT } from '@common/Button';
import routes from '@routes';
import { Wrapper, Form } from './style';

const SearchHeader = ({
  clearSearch,
  searchFunc,
  history,
  showUploadButton,
}) => (
  <Wrapper>
    <Formik
      initialValues={{ searchTerm: '' }}
      onSubmit={(values, { setSubmitting }) => {
        searchFunc(values.searchTerm);
        setSubmitting(false);
      }}
    >
      {({ values, resetForm, submitForm }) => (
        <Form>
          <TextField
            name="searchTerm"
            label={null}
            placeholder="Search by name or ID"
            textSize="normal"
            single
            icon="magnifier"
            onIconClick={submitForm}
            withClearButton={!!values.searchTerm}
            onClear={() => {
              resetForm();
              clearSearch();
            }}
          />
        </Form>
      )}
    </Formik>
    {showUploadButton ? (
      <Button
        variant={VARIANT.PRIMARY}
        text="Upload new"
        icon="upload"
        iconPosition="prefix"
        onClick={() => {
          history.push(routes.upload);
        }}
      />
    ) : null}
  </Wrapper>
);

SearchHeader.defaultProps = {
  showUploadButton: true,
};

SearchHeader.propTypes = {
  searchFunc: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  showUploadButton: PropTypes.bool,
};

export default withRouter(SearchHeader);
