import styled from '@emotion/styled';
import { Form as FormikForm } from 'formik';

export const Form = styled(FormikForm)`
  width: 100%;
  max-width: 350px;
`;

export const QRRow = styled.div`
  background-color: #fff;
  padding: 10px;
  margin: 30px auto 30px;
  width: 240px;
`;
