import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './style';

const CloseButton = ({ closeToast }) => (
  <Button type="button" onClick={closeToast}>
    <img src="images/icons/close.svg" alt="close" />
  </Button>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
};

export default CloseButton;
