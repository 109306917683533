import styled from '@emotion/styled';
import { getPalette } from '@styles/helpers';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: transparent;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  border: none;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 30px;
  position: relative;
  white-space: nowrap;

  &:before {
    content: '';
    display: block;
    width: 15px;
    height: 3px;
    background-color: ${(props) => getPalette(props).base};
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
  }

  ${(props) =>
    props.active
      ? `
        color: ${getPalette(props).base};
        &:before {
          width: 100%;
          opacity: 1;
        }

      `
      : null}

  &:focus {
    outline: none;
  }

  @media ${breakpoint.md} {
    padding-left: 10px;
  }
`;

export const SortIcons = styled.span`
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  svg {
    display: block;
    & + svg {
      margin-top: 1px;
    }

    path {
      transition: fill 0.3s ease-in-out;
      fill: #969696;
    }
  }

  ${(props) =>
    props.sort === 'asc' &&
    `
      .asc {
        path {
          fill: ${getPalette(props).base};
        }
      }`}

  ${(props) =>
    props.sort === 'desc' &&
    `
      .desc {
        path {
          fill: ${getPalette(props).base};
        }
      }`}
`;
