import React from 'react';
import { Formik } from 'formik';
import { withRouter, Redirect } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { PROCESS_PASSWORD_RESET } from '@graphQL/mutations';
import queryString from 'query-string';

import TextField from '@components/common/Form/TextField';
import Button, { VARIANT, SIZE } from '@common/Button';
import { createToast, TOAST_TYPES } from '@components/common/Toast';
import routes from '@routes';
import { Form, Row } from './style';

const ResetPasswordForm = ({ history, location }) => {
  const client = useApolloClient();
  const queryParams = queryString.parse(location.search);

  if (!queryParams.t) {
    return <Redirect to={routes.base} />;
  }

  const token = queryParams.t;
  return (
    <Formik
      initialValues={{ token, password: '', passwordConfirmation: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.password) {
          errors.password = 'Required';
        }
        if (!values.passwordConfirmation) {
          errors.passwordConfirmation = 'Required';
        }

        if (
          values.password &&
          values.passwordConfirmation &&
          values.password !== values.passwordConfirmation
        ) {
          errors.password = 'Passwords do not match';
          errors.passwordConfirmation = 'Passwords do not match';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const {
            data: { processPasswordReset },
          } = await client.mutate({
            mutation: PROCESS_PASSWORD_RESET,
            variables: { input: { ...values } },
          });

          if (processPasswordReset.success) {
            createToast(
              'Your password was changed successfully',
              TOAST_TYPES.SUCCESS,
            );
            history.push(routes.auth.signIn);
          } else {
            createToast(
              'Something went wrong, please try again',
              TOAST_TYPES.ERROR,
            );
          }
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          history.push(routes.auth.signIn);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <TextField
            name="password"
            type="password"
            label="Password"
            placeholder="*********"
            toggleable
            withStrengthBar
          />
          <TextField
            name="passwordConfirmation"
            type="password"
            label="Confirm Password"
            placeholder="*********"
            toggleable
          />
          <Row>
            <Button
              type="submit"
              text="Change password"
              icon="arrow-right"
              variant={VARIANT.PRIMARY}
              size={SIZE.LARGE}
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </Row>
          <Row>
            <Button
              type="button"
              text="Back to login"
              icon="arrow-right-dark"
              variant={VARIANT.SECONDARY}
              size={SIZE.MEDIUM}
              onClick={() => history.push(routes.auth.signIn)}
            />
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(ResetPasswordForm);
