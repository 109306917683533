import styled from '@emotion/styled';
import { getColors, getPalette } from '@styles/helpers';

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 5px 30px 5px 0;
  white-space: nowrap;
  color: ${(props) => {
    if (props.c) {
      return getPalette(props)[props.c];
    }
    return getColors(props).text.primary;
  }};
  cursor: ${(props) => (!props.as ? 'pointer' : 'default')};

  img {
    display: block;
    margin-right: 7px;
  }
`;
