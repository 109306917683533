import React from 'react';
import { Formik } from 'formik';
import { withRouter, Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';
// import jwtDecode from 'jwt-decode';
import { SETUP_ACCOUNT } from '@graphQL/mutations';
import { setItem, KEYS } from '@storage';

import { createToast, TOAST_TYPES } from '@common/Toast';
import TextField from '@components/common/Form/TextField';
import Button, { VARIANT, SIZE } from '@common/Button';
import routes from '@routes';
import { Form, Row } from './style';

const SetupAccountForm = ({ history, location: { search } }) => {
  const [setupAccountMutation] = useMutation(SETUP_ACCOUNT);
  const queryParams = queryString.parse(search);

  if (!queryParams.t) {
    return <Redirect to={routes.base} />;
  }

  return (
    <Formik
      initialValues={{
        token: queryParams.t,
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirmation: '',
      }}
      validate={(values) => {
        const errors = {};
        if (!values.password) {
          errors.password = 'Required';
        }
        if (!values.passwordConfirmation) {
          errors.passwordConfirmation = 'Required';
        }

        if (
          values.password &&
          values.passwordConfirmation &&
          values.passwordConfirmation !== values.password
        ) {
          errors.password = 'Passwords do not match';
          errors.passwordConfirmation = 'Passwords do not match';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          const {
            data: { setupAccount },
          } = await setupAccountMutation({
            variables: { input: { ...values } },
          });
          if (setupAccount.success) {
            const { authenticationStep } = setupAccount;

            setItem(KEYS.TOKEN_2_FA_SETUP, authenticationStep.token);
            setSubmitting(false);
            history.push(routes.auth.setup_2fa);
          }
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          setSubmitting(false);
          // history.push(routes.auth.signIn);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <TextField
            name="firstName"
            label="First name"
            placeholder="e.g. Joe"
          />
          <TextField name="lastName" label="Last name" placeholder="e.g. Doe" />
          <TextField
            name="password"
            type="password"
            label="Password"
            placeholder="*********"
            toggleable
            withStrengthBar
          />
          <TextField
            name="passwordConfirmation"
            type="password"
            label="Confirm Password"
            placeholder="*********"
            toggleable
          />
          <Row inline>
            <Button
              type="submit"
              text="Join Robin"
              icon="arrow-right"
              variant={VARIANT.PRIMARY}
              size={SIZE.LARGE}
              loading={isSubmitting}
            />
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(SetupAccountForm);
