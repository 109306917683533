import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './style';

const Text = ({ type, children, center, color, size, weight }) => {
  const Tag = Styled[type];

  return (
    <Tag size={size} color={color} center={center} weight={weight}>
      {children}
    </Tag>
  );
};

Text.defaultProps = {
  color: 100,
  type: 'Text',
  size: 'normal',
  center: false,
  weight: 'normal',
};

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  type: PropTypes.oneOf([
    'Display',
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'Text',
  ]),
  size: PropTypes.oneOf(['normal', 'small', 'big']),
  color: PropTypes.oneOf([100, 200, 300, 400, 'primary', 'white']),
  center: PropTypes.bool,
  weight: PropTypes.oneOf(['normal', 'bold']),
};

export default Text;
