import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { getBackgrounds, getColors } from '@styles/helpers';
import { breakpoint } from '@styles/breakpoints';

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Breadcrumbs = styled.div`
  display: flex;
  font-size: 13px;
  color: ${(props) => getColors(props).text.primary};
  font-weight: bold;

  img {
    display: block;
    margin: 0 10px;
  }
`;

export const BreadcrumbsItem = styled(NavLink)`
  text-decoration: none;
  color: ${(props) => getColors(props).text.primary};
  font-weight: bold;
`;

export const Details = styled.div`
  background-color: ${(props) => getBackgrounds(props).light.secondary};
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 30px 20px 45px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const Buttons = styled.div`
  display: flex;

  button + button {
    margin-left: 30px;
  }
`;

export const Column = styled.div`
  flex: 1;

  & + & {
    flex: 1.5;
    @media ${breakpoint.md} {
      flex: 1;
      margin-top: 30px;
    }
  }
`;

export const Section = styled.div`
  & + & {
    margin-top: 30px;
  }

  .select__control,
  .select__menu {
    max-width: 175px;
  }
`;

export const SectionTitle = styled.div`
  margin-bottom: 10px;
`;
