import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Cover } from './style';

const Loader = ({ cover, fixed, relative, size }) => (
  <>
    <Wrapper fixed={fixed} relative={relative} size={size}>
      <img src="images/preloader-white.svg" alt="loading" />
    </Wrapper>
    {cover ? <Cover /> : null}
  </>
);

Loader.defaultProps = {
  cover: false,
  fixed: false,
  relative: false,
  size: 50,
};

Loader.propTypes = {
  fixed: PropTypes.bool,
  relative: PropTypes.bool,
  size: PropTypes.number,
  cover: PropTypes.bool,
};

export default Loader;
