import styled from '@emotion/styled';
import { getColors, getPalette } from '@styles/helpers';

export const Wrapper = styled.div`
  color: ${(props) => (props.withError ? getColors(props).error : '#585757')};
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const Error = styled.div`
  color: ${(props) => getColors(props).error};
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  bottom: -24px;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-right: 6px;
  }
`;

export const Control = styled.div`
  background-color: #dbdbdb;
  border-radius: 10px;
  width: 40px;
  height: 20px;
  position: relative;
  transition: background-color 0.3s ease-in-out;
  margin-right: 10px;
`;

export const Handle = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  left: 3px;
  top: calc(50% - 7px);
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.03),
    0px 1px 1px rgba(0, 0, 0, 0.04);
  transition: left 0.3s ease-in-out;
  border-radius: 7px;
`;

export const Input = styled.input`
  height: ${(props) => (props.textSize === 'large' ? '48px' : '40px')};
  width: 1px;
  height: 1px;
  position: absolute;
  left: -9999px;

  &:checked {
    & + ${Label} {
      ${Control} {
        background-color: ${(props) => getPalette(props).base};

        ${Handle} {
          left: calc(100% - 17px);
        }
      }
    }
  }
`;
