import { gql } from '@apollo/client';

export const UPDATE_PASSWORD = gql`
  mutation($input: UpdatePasswordMutationInput!) {
    updatePassword(input: $input) {
      user {
        firstName
      }
    }
  }
`;
