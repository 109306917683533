import { gql } from '@apollo/client';

export const UPDATE_GROUP = gql`
  mutation($input: UpdateGroupMutationInput!) {
    updateGroup(input: $input) {
      group {
        name
        id
        users {
          edges {
            node {
              firstName
            }
          }
        }
      }
      clientMutationId
    }
  }
`;
