import styled from '@emotion/styled';
import { Form as FormikForm } from 'formik';
import { breakpoint } from '@styles/breakpoints';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;

  @media ${breakpoint.sm} {
    button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
`;

export const Form = styled(FormikForm)`
  flex: 1;
  max-width: 427px;

  @media ${breakpoint.sm} {
    width: 100%;
  }
`;
