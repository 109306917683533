import React from 'react';
import { Global, css } from '@emotion/core';
import reset from 'emotion-reset';
import { breakpoint } from '@styles/breakpoints';

const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        ${reset}

        @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

        * {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        body {
          background-color: #2a344f;
          font-family: 'Lato', sans-serif;
        }

        input,
        textarea {
          font-family: 'Lato', sans-serif;
        }

        html {
          box-sizing: border-box;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        #root {
          min-height: 100vh;
        }

        b {
          font-weight: 700;
        }

        button {
          background-color: transparent;
          border: none;

          &:focus {
            outline: none;
          }
        }

        .tooltip {
          position: absolute;
          background: #2a344f;
          border-radius: 4px;
          color: #fff;
          padding: 5px 10px;
          font-size: 12px;
          line-height: 18px;
          margin-left: 5px;
          z-index: 9999;
          transform: scale(0) translateY(-50%);
          max-width: 350px;
          opacity: 0;
          transition: opacity 0.3s ease-in-out, transfrom 0.3s ease-in-out;
          &.animate {
            transition: opacity 0.3s ease-in-out, transfrom 0.3s ease-in-out;
            opacity: 1;
            transform: scale(1) translateY(-50%);
          }
        }

        .Toastify__toast-container {
          width: calc(100% - 30px);
          top: 15px;
          left: 15px;
          transform: none;
          padding: 0;
        }

        .Toastify__toast {
          border-radius: 6px;
          padding: 24px 24px 24px 30px;

          small {
            font-size: 12px;
          }

          @media ${breakpoint.md} {
            padding: 15px 15px 15px 30px;
          }
        }

        .Toastify__close-button {
          align-self: center;
        }

        .Toastify__toast-body {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.3px;

          @media ${breakpoint.md} {
            font-size: 15px;
            line-height: 20px;
          }
        }

        .Toastify__progress-bar {
          height: 2px;
        }

        .Toastify__toast--info {
          background: #005ad4;
        }

        .Toastify__toast--error {
          background: #e15555;
        }

        .Toastify__toast--success {
          background: #27ae60;
        }

        .download-box {
          position: fixed;
          top: 15px;
          left: 50%;
          /* transform: translateX(-50%); */
          background: #f7f7f7;
          border-radius: 10px;
          padding: 20px 30px;
          font-size: 12px;
          text-align: center;
          transform: translateY(-100px);
          transition: transform 0.2s cubic-bezier(0.42, 0, 0.05, 2.11);
          box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.05),
            0px 9px 46px rgba(0, 0, 0, 0.03), 0px 24px 38px rgba(0, 0, 0, 0.04);

          &__progress {
            width: 200px;
            background: rgba(0, 90, 212, 0.2);
            border-radius: 2px;
            height: 4px;
            overflow: hidden;
            margin-top: 5px;

            > div {
              background-color: #005ad4;
              width: 0%;
              height: 100%;
              transition: width 0.1s ease-in-out;
            }
          }

          &.animate {
            transform: translateY(0);
          }
        }
      `}
    />
  );
};

export default GlobalStyle;
