import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import { UPDATE_USER_STATUS } from '@graphQL/mutations';
import { USERS } from '@graphQL/queries';
import { createToast, TOAST_TYPES } from '@common/Toast';
import FieldText from '@common/Form/TextField';
import Button, { SIZE, VARIANT } from '@common/Button';
import routes from '@routes';

import { Column, Form, Field } from './style';

const UserForm = ({ suspended, user, showLabels }) => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const changeUserStatus = async () => {
    setLoading(true);
    try {
      await client.mutate({
        mutation: UPDATE_USER_STATUS,
        variables: {
          input: { user: user.id, isEnabled: suspended },
        },
        refetchQueries: [{ query: USERS }],
      });

      const ToastMsg = () => (
        <div>
          User{' '}
          <b>
            {user.firstName} {user.lastName}
          </b>{' '}
          <small>({user.email})</small>
          {suspended ? ' has been activated' : ' has been suspended'}
        </div>
      );

      createToast(ToastMsg, TOAST_TYPES.SUCCESS);
    } catch (error) {
      createToast(error.message, TOAST_TYPES.ERROR);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Formik
      initialValues={{
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        role: user.userType?.name || '',
        email: user.email,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(false);
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Column>
            <Field flex={1.5}>
              <FieldText
                name="firstName"
                label={showLabels ? 'First name' : null}
                textSize="normal"
                readOnly
                single
                disabled
              />
            </Field>
            <Field flex={1.5}>
              <FieldText
                name="lastName"
                label={showLabels ? 'Last name' : null}
                textSize="normal"
                readOnly
                single
                disabled
              />
            </Field>
            <Field flex={1.5}>
              <FieldText
                name="email"
                label={showLabels ? 'Email' : null}
                textSize="normal"
                readOnly
                single
                disabled
              />
            </Field>
            <Field>
              <FieldText
                name="role"
                label=""
                textSize="normal"
                readOnly
                single
                disabled
              />
            </Field>
          </Column>

          <Button
            text={suspended ? 'Enable' : 'Disable'}
            icon={suspended ? 'circle-check' : 'bin'}
            iconPosition="prefix"
            size={SIZE.MEDIUM}
            variant={suspended ? VARIANT.PRIMARY : VARIANT.DANGER}
            onClick={changeUserStatus}
            loading={loading}
            disabled={loading}
          />

          <Button
            text="User settings"
            icon="user"
            iconPosition="prefix"
            size={SIZE.MEDIUM}
            variant={VARIANT.TERTIARY}
            onClick={() => history.push(routes.userSettings(user.id))}
          />
        </Form>
      )}
    </Formik>
  );
};

UserForm.propTypes = {
  user: PropTypes.shape({}).isRequired,
  suspended: PropTypes.bool.isRequired,
  showLabels: PropTypes.bool.isRequired,
};

export default UserForm;
