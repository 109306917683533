import styled from '@emotion/styled';
import { Form as FormikForm } from 'formik';

export const Form = styled(FormikForm)`
  width: 100%;
  max-width: 280px;
`;

export const Title = styled.div`
  margin-bottom: 40px;
`;

export const File = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
  margin-bottom: 30px;
  border: 1px solid #005ad4;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.03),
    0px 1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 14px 18px;
`;

export const FileDetails = styled.div`
  margin-left: 15px;
  flex: 1;
  overflow: hidden;

  > h5 {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const FileDelete = styled.button`
  cursor: pointer;
  margin-top: 0;
  margin-left: 15px;
`;

export const ButtonRow = styled.div`
  margin-top: 30px;
`;
