import React from 'react';
import Form from './components/Form';
import Layout from '../layout';

const SignIn = () => (
  <Layout>
    <Form />
  </Layout>
);

export default SignIn;
