import { gql } from '@apollo/client';

export const USER = gql`
  query {
    user {
      id
      firstName
      lastName
      email
      client {
        areGroupsEnabled
      }
    }
  }
`;

export const USERS = gql`
  query {
    users {
      edges {
        node {
          id
          firstName
          lastName
          isEnabled
          email
          userType {
            id
            name
          }
        }
      }
    }
  }
`;

export const USER_TYPES = gql`
  query {
    userTypes {
      id
      name
    }
  }
`;

export const USER_BY_ID = gql`
  query($id: ID) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      userType {
        name
        id
      }
    }
  }
`;

export const USER_BY_NAME = gql`
  query($name: String) {
    users(name: $name) {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
