import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Text from '@common/Text';
import InfoTip from '@common/InfoTip';

import {
  Error,
  Input,
  Label,
  Wrapper,
  Control,
  Title,
  Group,
  GroupItem,
} from './style';

const RadioGroup = ({ label, infoTip, options, ...props }) => {
  const [field, meta, helpers] = useField(props);

  const fieldId = `field_${field.name}`;
  const hasError = meta.touched && meta.error;

  return (
    <Wrapper withError={hasError}>
      {label ? (
        <Title>
          <Text color={200}>{label}</Text>
          {infoTip ? <InfoTip tip={infoTip} /> : null}
        </Title>
      ) : null}

      <Group>
        {options.map((option, index) => (
          <GroupItem key={`radio-group-item_${index}`}>
            <Input
              id={`${fieldId}_${index}`}
              withError={hasError}
              type="radio"
              value={option.value}
              disabled={option.disabled}
              {...field}
              {...props}
              onChange={() => helpers.setValue(option.value)}
            />
            <Label htmlFor={`${fieldId}_${index}`}>
              <Control />
              <Text color={200}>{option.label}</Text>
            </Label>
          </GroupItem>
        ))}
      </Group>

      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </Wrapper>
  );
};

RadioGroup.defaultProps = {
  infoTip: null,
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  infoTip: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]),
      disabled: PropTypes.bool,
    }),
  ).isRequired,
};

export default RadioGroup;
