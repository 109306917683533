import styled from '@emotion/styled';
import { Form as FormikForm } from 'formik';

export const Form = styled(FormikForm)`
  width: 100%;
  max-width: 350px;
`;

export const Row = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.bottom};
`;
