import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Icon } from './style';

const InfoTip = ({ tip }) => {
  const iconRef = useRef();
  let TipTooltip;

  const createTooltip = () => {
    const { x, y, width, height } = iconRef.current.getBoundingClientRect();
    TipTooltip = document.createElement('div');
    TipTooltip.setAttribute('class', 'tooltip');
    TipTooltip.style.left = `${x + width}px`;
    TipTooltip.style.top = `${y + height / 2}px`;
    TipTooltip.textContent = tip;

    setTimeout(() => TipTooltip.classList.add('animate'), 100);
    document.body.appendChild(TipTooltip);
  };

  const mouseEnterHandler = () => {
    createTooltip();
  };

  const mouseLeaveHandler = (ev) => {
    document.body.removeChild(TipTooltip);
  };

  return (
    <>
      <Wrapper>
        <Icon
          type="button"
          ref={iconRef}
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          <img src="images/icons/help.svg" alt="?" />
        </Icon>
      </Wrapper>
    </>
  );
};

InfoTip.propTypes = {
  tip: PropTypes.string.isRequired,
};

export default InfoTip;
