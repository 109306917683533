import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Text from '@common/Text';
import PasswordStrengthBar from './components/PasswordStrengthBar';

import {
  ClearButton,
  CrossLine,
  Error,
  Input,
  Label,
  Wrapper,
  SwitchReadable,
  Icon,
} from './style';

const TextField = ({
  label,
  type,
  toggleable,
  icon,
  textSize,
  single,
  withStrengthBar,
  textarea,
  onChange,
  onClear,
  onIconClick,
  withClearButton,
  ...props
}) => {
  const [readableValue, setReadableValue] = useState(false);
  const [field, meta] = useField(props);

  const fieldId = `field_${field.name}`;
  const hasError = meta.touched && meta.error;

  let inputType = type;
  if (type === 'password' && toggleable) {
    inputType = readableValue ? 'text' : 'password';
  }

  const onChangeHandler = (ev) => {
    if (typeof onChange === 'function') {
      onChange(ev);
    }

    field.onChange(ev);
  };

  return (
    <Wrapper withError={hasError} single={single}>
      {label ? (
        <Label htmlFor={fieldId}>
          <Text color={200}>{label}</Text>
        </Label>
      ) : null}
      {type === 'password' && toggleable ? (
        <SwitchReadable
          type="button"
          onClick={() => setReadableValue(!readableValue)}
          readable={readableValue}
          tabIndex={-1}
        >
          <img src="images/icons/eye.svg" alt="toggle" />
          <CrossLine />
        </SwitchReadable>
      ) : null}
      {icon ? (
        <Icon
          withLabel={!!label}
          src={`images/icons/${icon}.svg`}
          alt=""
          onClick={onIconClick}
        />
      ) : null}

      <Input
        as={textarea ? 'textarea' : 'input'}
        id={fieldId}
        withError={hasError}
        withIcon={!!icon}
        type={inputType}
        textSize={textSize}
        {...field}
        {...props}
        onChange={onChangeHandler}
      />

      <ClearButton
        type="button"
        onClick={onClear}
        withIcon={!!icon}
        className={withClearButton ? 'visible' : null}
        tabIndex={-1}
      >
        <img src="images/icons/close-primary.svg" alt="clear" />
      </ClearButton>

      {withStrengthBar ? <PasswordStrengthBar password={field.value} /> : null}
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </Wrapper>
  );
};

TextField.defaultProps = {
  label: null,
  type: 'text',
  autoFocus: false,
  toggleable: false,
  icon: null,
  textSize: 'large',
  single: false,
  withStrengthBar: false,
  textarea: false,
  onChange: null,
  withClearButton: false,
  onClear: null,
  onIconClick: null,
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'number', 'phone', 'password']),
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  toggleable: PropTypes.bool,
  icon: PropTypes.string,
  textSize: PropTypes.oneOf(['normal', 'large']),
  single: PropTypes.bool,
  withStrengthBar: PropTypes.bool,
  textarea: PropTypes.bool,
  onChange: PropTypes.func,
  withClearButton: PropTypes.bool,
  onClear: PropTypes.func,
  onIconClick: PropTypes.func,
};

export default TextField;
