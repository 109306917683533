import { gql } from '@apollo/client';

export const SETUP_2FA_CONFIG = gql`
  mutation($input: SetupTwoFactorConfigMutationInput!) {
    setupTwoFactorConfig(input: $input) {
      secret
      provisioningUri
    }
  }
`;
