import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import TextField from '@common/Form/TextField';
import Text from '@common/Text';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${(props) => (props.editMode ? '350px' : 'none')};
`;

const Button = styled.button`
  cursor: pointer;
`;

const EditInPlace = ({ fieldName, editMode, setEditMode, value }) => {
  if (editMode) {
    return (
      <Wrapper editMode={editMode}>
        <TextField name={fieldName} autoFocus single />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Text type="H3">{value || 'UNNAMED'}</Text>
      <Button type="button" onClick={() => setEditMode(true)}>
        <img src="images/icons/pencil.svg" alt="" />
      </Button>
    </Wrapper>
  );
};

EditInPlace.defaultProps = {
  value: '',
};

EditInPlace.propTypes = {
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.string,
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
};

export default EditInPlace;
