import { gql } from '@apollo/client';

export const SUBMIT_DOCUMENT_TO_REVIEW = gql`
  mutation submitDocumentForReview(
    $input: SubmitDocumentForReviewMutationInput!
  ) {
    submitDocumentForReview(input: $input) {
      document {
        id
        name
        originalFilename
        status
        createdAt
        group {
          name
        }
        documentType {
          name
        }
        playbook {
          id
          name
        }
        uploadComment
      }
      clientMutationId
    }
  }
`;
