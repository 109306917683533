import { gql } from '@apollo/client';

export const DOCUMENTS = gql`
  query {
    documents {
      totalCount
      edges {
        node {
          id
          name
          originalFilename
          sentForReviewDate
          status
          createdAt
          group {
            name
          }
          documentType {
            name
          }
          playbook {
            id
            name
          }
          uploadComment
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const DOCUMENTS_SEARCH = gql`
  query(
    $searchTerm: String
    $orderBy: String
    $afterCursor: String
    $beforeCursor: String
    $first: Int
    $last: Int
  ) {
    documents(
      first: $first
      last: $last
      after: $afterCursor
      before: $beforeCursor
      searchTerm: $searchTerm
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          documentId
          originalFilename
          status
          createdAt
          sentForReviewDate
          group {
            name
          }
          documentType {
            name
          }
          playbook {
            id
            name
          }
          uploadComment
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const DOCUMENT = gql`
  query($documentId: ID!) {
    document(id: $documentId) {
      id
      name
      documentId
      originalFilename
      status
      createdAt
      sentForReviewDate
      group {
        name
      }
      documentType {
        name
        id
      }
      playbook {
        id
        name
      }
      uploadComment
    }
  }
`;

export const DOCUMENT_TYPES = gql`
  query {
    documentTypes {
      id
      name
    }
  }
`;
