import { gql } from '@apollo/client';

export const TRIGGER_PASSWORD_RESET = gql`
  mutation($input: TriggerPasswordResetMutationInput!) {
    triggerPasswordReset(input: $input) {
      success
    }
  }
`;

export const PROCESS_PASSWORD_RESET = gql`
  mutation($input: ProcessPasswordResetMutationInput!) {
    processPasswordReset(input: $input) {
      success
      authenticationToken {
        refreshToken
      }
      authenticationStep {
        token
        nextStep
      }
    }
  }
`;
