/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Text from '@common/Text';
import { Wrapper, SortIcons } from './style';

const HeaderCell = ({ text, onClick, sort }) => (
  <Wrapper onClick={onClick} active={!!sort}>
    <Text type="H5" color={sort ? 'primary' : 100}>
      {text}
    </Text>

    {onClick ? (
      <SortIcons sort={sort}>
        <svg
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="asc"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.85615 0.798351L7.40685 2.40378C8.43083 3.4639 8.09516 4.32329 6.66104 4.32329H1.3431C-0.0927743 4.32329 -0.435086 3.47259 0.597293 2.40378L2.148 0.798351C3.17197 -0.261764 4.82377 -0.270462 5.85615 0.798351Z"
          />
        </svg>

        <svg
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="desc"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.66104 0.434448C8.04571 0.434448 8.40637 1.23559 7.50794 2.24503L7.40685 2.35396L5.85615 3.95939C4.86678 4.98366 3.30855 5.01835 2.27917 4.08642L2.148 3.95939L0.597293 2.35396C-0.399487 1.322 -0.114747 0.493376 1.19848 0.437457L1.3431 0.434448H6.66104Z"
          />
        </svg>
      </SortIcons>
    ) : null}
  </Wrapper>
);

HeaderCell.defaultProps = {
  sort: null,
  onClick: null,
};

HeaderCell.propTypes = {
  text: PropTypes.string.isRequired,
  sort: PropTypes.oneOf(['asc', 'desc']),
  onClick: PropTypes.func,
};

export default HeaderCell;
