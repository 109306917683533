import React, { useState } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';
import { DOCUMENTS_SEARCH } from '@graphQL/queries';
import { createToast, TOAST_TYPES } from '@common/Toast';
import Documents from '@components/authenticated/Documents';
import SearchHeader from '@components/authenticated/SearchHeader';
import Layout from '@components/authenticated/layout';

const RECORDS_PER_PAGE = 12;

const Home = ({ uuid }) => {
  const [initialized, setInitialized] = useState(false);
  const [firstCursor, setFirstCursor] = useState('');
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [variables, setVariables] = useState({
    searchTerm: '',
    orderBy: '-uploadDate',
    first: RECORDS_PER_PAGE,
  });

  const { loading, error, data } = useQuery(DOCUMENTS_SEARCH, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: ({ documents: { pageInfo } }) => {
      if (!initialized) {
        setFirstCursor(pageInfo.startCursor);
        setInitialized(true);
      }

      if (pageInfo.startCursor === firstCursor && hasPrevPage) {
        setHasPrevPage(false);
      }
    },
  });

  if (error) {
    createToast(error.message, TOAST_TYPES.ERROR);
  }

  const documents = get(data, 'documents.edges', []);
  const pageInfo = get(data, 'documents.pageInfo', {});

  const search = (input) => {
    setVariables((v) => ({ ...v, searchTerm: input }));
  };

  const clearSearch = () => {
    setVariables((v) => ({ ...v, searchTerm: '' }));
  };

  const nextPageHandler = () => {
    setHasPrevPage(true);
    setHasNextPage(false);
    setVariables((v) => {
      delete v.beforeCursor;
      delete v.last;
      return {
        ...v,
        afterCursor: pageInfo.endCursor,
        first: RECORDS_PER_PAGE,
      };
    });
  };
  const prevPageHandler = () => {
    setHasNextPage(true);
    setVariables((v) => {
      delete v.afterCursor;
      delete v.first;
      return {
        ...v,
        beforeCursor: pageInfo.startCursor,
        last: RECORDS_PER_PAGE,
      };
    });
  };

  return (
    <Layout title="Your Documents">
      <SearchHeader searchFunc={search} clearSearch={clearSearch} />
      <Documents
        items={documents}
        totalCount={get(data, 'documents.totalCount', 0)}
        loading={loading}
        nextPageHandler={nextPageHandler}
        prevPageHandler={prevPageHandler}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage || !!pageInfo.hasNextPage}
        queryVariables={variables}
        setQueryVariables={setVariables}
      />
    </Layout>
  );
};

export default Home;
