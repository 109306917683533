import { gql } from '@apollo/client';

export const UPDATE_ACCOUNT_NAME = gql`
  mutation($input: UpdateAccountNameMutationInput!) {
    updateAccountName(input: $input) {
      user {
        firstName
        lastName
      }
    }
  }
`;
