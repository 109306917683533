/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { ButtonWrapper, ButtonText, Icon, Preloader } from './style'; // eslint-disable-line import/no-cycle

export const VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  DANGER: 'danger',
};

export const SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

const Button = ({
  onClick,
  text,
  type,
  size,
  variant,
  icon,
  iconPosition,
  loading,
  disabled,
  light,
}) => (
  <ButtonWrapper
    type={type}
    onClick={onClick}
    variant={variant}
    size={size}
    loading={loading ? 1 : 0}
    disabled={disabled}
    iconPos={iconPosition}
    light={light}
  >
    {icon && iconPosition === 'prefix' ? (
      <Icon src={`images/icons/${icon}.svg`} />
    ) : null}
    <ButtonText loading={loading ? 1 : 0}>{text}</ButtonText>
    {icon && iconPosition === 'suffix' ? (
      <Icon src={`images/icons/${icon}.svg`} />
    ) : null}
    {loading ? (
      <Preloader>
        <img
          src={
            variant === VARIANT.PRIMARY
              ? 'images/preloader-white.svg'
              : 'images/preloader-dark.svg'
          }
          alt="loading"
        />
      </Preloader>
    ) : null}
  </ButtonWrapper>
);

Button.defaultProps = {
  type: 'button',
  text: null,
  onClick: null,
  variant: 'primary',
  size: 'medium',
  icon: null,
  loading: false,
  disabled: false,
  iconPosition: 'suffix',
  light: false,
};

Button.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  text: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(VARIANT)),
  size: PropTypes.oneOf(Object.values(SIZE)),
  icon: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['prefix', 'suffix']),
  light: PropTypes.bool,
};

export default Button;
