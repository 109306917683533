import styled from '@emotion/styled';
import { Form as FormikForm } from 'formik';
import { getBackgrounds } from '@styles/helpers';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;

export const Main = styled.div`
  flex: 1;
  padding-right: 16px;
`;

export const Header = styled.div`
  display: flex;
  height: 103px;
  align-items: center;
  color: #fff;
  justify-content: space-between;
`;

export const Form = styled(FormikForm)`
  width: 100%;
  max-width: 350px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
  margin: -30px -40px 20px;
  padding: 30px 40px;
  background: ${(props) => getBackgrounds(props).light.secondary};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media ${breakpoint.md} {
    margin: -15px -15px 10px;
    height: auto;
    flex-wrap: wrap;
    padding: 15px;
    text-align: center;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;
