import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Text from '@common/Text';
import Button, { SIZE, VARIANT } from '@common/Button';
import {
  Wrapper,
  Content,
  Header,
  Footer,
  CounterWrapper,
  Counter,
  CounterTitle,
} from '@components/authenticated/Groups/components/Group/style';
import routes from '@routes';

const Group = ({ edit, group }) => {
  const { push } = useHistory();
  return (
    <Wrapper key={`group-${group.id}`} isPrivate={group.isPrivate}>
      <Header>
        <Text type="H4" center>
          {group.name}
        </Text>
      </Header>
      <Content>
        <CounterWrapper>
          <Counter green count={group.reviewCount}>
            {group.reviewCount}
          </Counter>
          <CounterTitle>PROCESSING</CounterTitle>
        </CounterWrapper>
        <CounterWrapper>
          <Counter amber count={group.reviewedCount}>
            {group.reviewedCount}
          </Counter>
          <CounterTitle>PROCESSED</CounterTitle>
        </CounterWrapper>
      </Content>
      <Footer>
        <Button
          text="Documents"
          size={SIZE.SMALL}
          onClick={() => push(routes.groupDocuments(group.id))}
        />
        {group.isPrivate ? (
          <Button
            text="Members"
            size={SIZE.SMALL}
            variant={VARIANT.TERTIARY}
            onClick={() => edit(group)}
          />
        ) : (
          <Button
            text="Public"
            size={SIZE.SMALL}
            variant={VARIANT.SECONDARY}
            light
            onClick={() => edit(group)}
          />
        )}
      </Footer>
    </Wrapper>
  );
};

Group.propTypes = {
  group: PropTypes.shape({}).isRequired,
  edit: PropTypes.func.isRequired,
};

export default Group;
