import React from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { UPDATE_EMAIL, RENEW_ACCESS_TOKEN } from '@graphQL/mutations';

import { createToast, TOAST_TYPES } from '@common/Toast';
import TextField from '@components/common/Form/TextField';
import Button, { VARIANT, SIZE } from '@common/Button';
import { renewSession } from '@utils/session';
import { Form } from '../../style';

const ChangeEmail = () => {
  const [updateEmail] = useMutation(UPDATE_EMAIL);
  const [renewAccessToken] = useMutation(RENEW_ACCESS_TOKEN);

  return (
    <Formik
      initialValues={{ email: '', emailConfirmation: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
          errors.email = 'Email address is invalid';
        }
        if (!values.emailConfirmation) {
          errors.emailConfirmation = 'Required';
        }

        if (
          values.email &&
          values.emailConfirmation &&
          values.email !== values.emailConfirmation
        ) {
          errors.email = "Email's don't match";
          errors.emailConfirmation = "Email's don't match";
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await updateEmail({
            variables: { input: { email: values.email } },
          });

          createToast(
            'Your email was successfully updated',
            TOAST_TYPES.SUCCESS,
          );
          await renewSession(renewAccessToken);
          resetForm();
          setSubmitting(false);
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <TextField name="email" label="New email" type="email" />
          <TextField
            name="emailConfirmation"
            type="email"
            label="Confirm new email"
          />
          <Button
            type="submit"
            text="Save changes"
            icon="disk"
            iconPosition="prefix"
            variant={VARIANT.PRIMARY}
            size={SIZE.LARGE}
            loading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ChangeEmail;
