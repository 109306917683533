import { gql } from '@apollo/client';

export const UPDATE_EMAIL = gql`
  mutation($input: UpdateEmailMutationInput!) {
    updateEmail(input: $input) {
      user {
        email
      }
      errors {
        field
        messages
      }
    }
  }
`;
