import { gql } from '@apollo/client';

export const GROUPS = gql`
  query($name: String) {
    groups(name: $name) {
      edges {
        node {
          id
          name
          isPrivate
          reviewedCount
          reviewCount
          users {
            edges {
              node {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

export const GROUP = gql`
  query(
    $groupId: ID!
    $searchTerm: String
    $orderBy: String
    $afterCursor: String
    $beforeCursor: String
    $first: Int
    $last: Int
  ) {
    group(id: $groupId) {
      id
      name
      client {
        name
      }
      reviewedCount
      reviewCount
      documents(
        first: $first
        last: $last
        after: $afterCursor
        before: $beforeCursor
        searchTerm: $searchTerm
        orderBy: $orderBy
      ) {
        totalCount
        edges {
          node {
            id
            documentId
            name
            originalFilename
            status
            createdAt
            group {
              name
            }
            documentType {
              name
            }
            playbook {
              id
              name
            }
            uploadComment
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
      users {
        edges {
          node {
            firstName
          }
        }
      }
    }
  }
`;
