import React from 'react';
import { Formik } from 'formik';
import get from 'lodash/get';
import map from 'lodash/map';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_USER } from '@graphQL/mutations';
import { USER_TYPES } from '@graphQL/queries';
import { mapFormErrors } from '@graphQL/apolloClient';
import { createToast, TOAST_TYPES } from '@common/Toast';
import TextField from '@common/Form/TextField';
import Button, { SIZE } from '@common/Button';
import SelectField from '@common/Form/Select';
import {
  Form,
  Column,
  Field,
} from '@components/authenticated/Users/components/NewUser/style';

const InviteUser = () => {
  const [createUser] = useMutation(CREATE_USER, { errorPolicy: 'all' });
  const { data: userTypesQuery } = useQuery(USER_TYPES);

  const userTypes = map(get(userTypesQuery, 'userTypes'), (item) => ({
    label: item.name,
    value: item.id,
  }));

  const submitHandler = async (values, setErrors, resetForm) => {
    try {
      const {
        data: createUserMutation,
        errors: mutationErrors,
      } = await createUser({
        variables: {
          input: {
            ...values,
            firstName: '',
            lastName: '',
          },
        },
      });

      const errors = mapFormErrors(createUserMutation, 'createUser');

      if (errors) {
        setErrors(errors);
      } else if (mutationErrors) {
        mutationErrors.forEach((err) =>
          createToast(err.message, TOAST_TYPES.ERROR),
        );
      } else {
        createToast(`Invite sent to ${values.email}`, TOAST_TYPES.SUCCESS);
        resetForm();
      }
    } catch (error) {
      createToast(error.message, TOAST_TYPES.ERROR);
      console.error(error); // eslint-disable-line no-console
    }
  };

  return (
    <Formik
      initialValues={{ email: '', userType: '' }}
      validate={(values) => {
        const errors = {};

        if (!values.userType) {
          errors.userType = 'Required';
        }

        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        try {
          await submitHandler(values, setErrors, resetForm);
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Column>
            <Field flex={1.5}>
              <TextField
                name="email"
                placeholder="e.g. joe.bloggs@lawsociety.com"
                label="Invite via email"
                single
              />
            </Field>
            <Field flex={1.5}>
              <SelectField
                name="userType"
                label="User type"
                options={userTypes}
                single
              />
            </Field>
          </Column>
          <Button
            type="submit"
            text="Send invite"
            icon="send-mail"
            iconPosition="prefix"
            size={SIZE.LARGE}
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default InviteUser;
