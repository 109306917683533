import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;

  @media ${breakpoint.md} {
    min-height: calc(100vh - 60px);
    padding-top: 60px;
  }
`;

export const Main = styled.div`
  flex: 1;
  padding-right: 16px;

  @media ${breakpoint.md} {
    padding: 0 16px;
    max-width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  height: 103px;
  align-items: center;
  color: #fff;
  justify-content: space-between;

  @media ${breakpoint.md} {
    height: 60px;
  }
`;

export const Surface = styled.div`
  position: relative;
  background: #fff;
  border-radius: 10px;
  min-height: 200px;
  margin-bottom: 30px;
  padding: ${(props) => props.padding || '30px 40px'};
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.05),
    0px 9px 46px rgba(0, 0, 0, 0.03), 0px 24px 38px rgba(0, 0, 0, 0.04);

  @media ${breakpoint.md} {
    padding: 15px;
  }
`;
