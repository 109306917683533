import React from 'react';
import PropTypes from 'prop-types';
import Text from '@common/Text';
import CircularProgressBar from './CircuralProgress';
import { Title, LoaderWrapper } from './style';
import { FormWrapper } from '../../style';

const UploadProgress = ({ progress }) => (
  <FormWrapper>
    <Title>
      <Text type="H3" center>
        Upload a contract
      </Text>
    </Title>

    <LoaderWrapper>
      <CircularProgressBar size={47} strokeWidth={3} percentage={progress} />
      <Text type="H6" color={300}>
        {progress}%
      </Text>
    </LoaderWrapper>
  </FormWrapper>
);

UploadProgress.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default UploadProgress;
