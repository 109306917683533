/* eslint-disable max-len */
import styled from '@emotion/styled';

export const FormWrapper = styled.div`
  background-color: ${(props) =>
    props.active ? 'rgba(0, 90, 212, 0.1)' : 'rgba(0, 90, 212, 0.04)'};
  display: flex;
  flex-direction: column;
  min-height: 530px;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23005AD4FF' stroke-width='1' stroke-dasharray='10%2c20' stroke-dashoffset='27' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 50px 30px 80px;
`;
