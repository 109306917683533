export default {
  base: '/',
  auth: {
    signIn: '/login',
    join: '/join',
    login_2fa: '/2fa-login',
    setup_2fa: '/2fa-setup',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
  },
  home: '/home',
  document: (id = null) => (id ? `/home/${id}` : '/home/:docId'),
  upload: '/upload',
  users: '/users',
  userSettings: (id = null) => (id ? `/users/${id}` : '/users/:userId'),
  groups: '/groups',
  groupDocuments: (id = null) => (id ? `/groups/${id}` : '/groups/:groupId'),
  settings: '/settings',
};
