import styled from '@emotion/styled';
import { getButtons, getFonts, getPalette, getColors } from '@styles/helpers';
import { VARIANT, SIZE } from './index'; // eslint-disable-line import/no-cycle

export const ButtonText = styled.span`
  font-weight: ${(props) => getFonts(props).bold};
  opacity: ${(props) => (props.loading ? 0.3 : 1)};
`;

export const Icon = styled.img`
  display: block;
`;

export const ButtonWrapper = styled.button`
  position: relative;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
  pointer-events: ${(props) => (props.loding ? 'none' : 'all')};

  ${(props) => {
    switch (props.variant) {
      case VARIANT.PRIMARY:
        return `
          background-color: ${getButtons(props).primary};
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.04);
          ${ButtonText} {
            color: ${getPalette(props).white};
          }

          &:hover {
            background-color: ${getButtons(props).primaryHover}
          }
        `;
      case VARIANT.SECONDARY:
        return `
          background-color: ${getButtons(props).secondary};
          box-shadow: ${
            props.light
              ? 'none'
              : '0px 1px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.04)'
          };
          border: 1px solid ${props.light ? '#f7f7f7' : '#DBDBDB'};
          ${ButtonText} {
            color: ${getColors(props).text[100]};
          }

          &:hover {
            background-color: ${getButtons(props).secondaryHover}
          }


        `;
      case VARIANT.TERTIARY:
        return `
          background-color: transparent;
          filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.05)),
                  drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.03)),
                  drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.04));
          border: 1px solid #005AD4;
          ${ButtonText} {
            color: ${getColors(props).text.primary};
          }

          &:hover {
            background-color: ${getButtons(props).secondaryHover}
          }
        `;

      case VARIANT.DANGER:
        return `
          background-color: ${getButtons(props).danger};
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.04);
          border: 1px solid ${getButtons(props).danger};
          color: #fff;
          ${ButtonText} {
            color: ${getColors(props).text.white};
          }

          &:hover {
            background-color: ${getButtons(props).dangerHover}
          }
        `;
      default:
        return `background-color: transparent`;
    }
  }}
  ${(props) => {
    switch (props.size) {
      case SIZE.SMALL:
        return `
          padding: 5px 10px;

          ${ButtonText} {
            font-size: 13px;
            line-height: 18px;
          }

          ${Icon} {
            margin-left: ${props.iconPos === 'suffix' ? '10px' : 0};
            margin-right: ${props.iconPos === 'prefix' ? '10px' : 0};
          }
        `;
      case SIZE.MEDIUM:
        return `
          padding: 8px 12px;
          ${ButtonText} {
            font-size: 15px;
            line-height: 22px;
          }
          ${Icon} {
            margin-left: ${props.iconPos === 'suffix' ? '15px' : '3px'};
            margin-right: ${props.iconPos === 'prefix' ? '10px' : '3px'};
          }
        `;
      case SIZE.LARGE:
        return `
          padding: 11px 16px;

          ${ButtonText} {
            font-size: 20px;
            line-height: 26px;
          }

          ${Icon} {
            margin-left: ${props.iconPos === 'suffix' ? '18px' : '4px'};
            margin-right: ${props.iconPos === 'prefix' ? '14px' : '2px'};
          }
        `;

      default:
        return `background-color: ${getButtons(props).primary}`;
    }
  }};

  &:focus {
    outline: none;
  }
`;

export const Preloader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;
