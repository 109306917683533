import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Wrapper, Box, Content, Cover, CloseBtn } from './style';

const Modal = ({ children, close }) => {
  useEffect(() => {
    const handleEscPress = ({ which }) => {
      if (which === 27) {
        close();
      }
    };

    window.addEventListener('keydown', handleEscPress);

    return () => {
      window.removeEventListener('keydown', handleEscPress);
    };
  }, [close]);

  const content = (
    <Wrapper>
      <Box>
        <CloseBtn type="button" onClick={close}>
          <img src="images/icons/close-primary.svg" alt="close" />
        </CloseBtn>
        <Content>{children}</Content>
      </Box>
      <Cover onClick={close} />
    </Wrapper>
  );

  return ReactDOM.createPortal(
    content,
    document.querySelector('#modal-placeholder'),
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  close: PropTypes.func.isRequired,
};

export default Modal;
