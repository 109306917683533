import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TmpButtonsWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 30px;
  left: 0;
  text-align: center;
`;
