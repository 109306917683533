import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { UPDATE_PASSWORD } from '@graphQL/mutations';

import { createToast, TOAST_TYPES } from '@common/Toast';
import TextField from '@common/Form/TextField';
import Button, { VARIANT, SIZE } from '@common/Button';
import { Form } from '@components/authenticated/UserSettings/style';

const ChangePassword = ({ user }) => {
  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  return (
    <Formik
      initialValues={{ password: '', passwordConfirmation: '', user: user.id }}
      validate={(values) => {
        const errors = {};
        if (!values.password) {
          errors.password = 'Required';
        }
        if (!values.passwordConfirmation) {
          errors.passwordConfirmation = 'Required';
        }
        if (
          values.password &&
          values.passwordConfirmation &&
          values.password !== values.passwordConfirmation
        ) {
          errors.password = 'Passwords do not match';
          errors.passwordConfirmation = 'Passwords do not match';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
        try {
          await updatePassword({
            variables: { input: { ...values } },
          });

          createToast(
            `${user.firstName} ${user.lastName}'s password was successfully saved`,
            TOAST_TYPES.SUCCESS,
          );
          resetForm();
          setSubmitting(false);
        } catch (error) {
          createToast(error.message, TOAST_TYPES.ERROR);
          setErrors({
            password: error.message,
            passwordConfirmation: error.message,
          });
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <TextField
            name="password"
            label=" New password"
            placeholder="*********"
            type="password"
            toggleable
            withStrengthBar
          />
          <TextField
            name="passwordConfirmation"
            type="password"
            label="Confirm new password"
            placeholder="*********"
            toggleable
          />
          <Button
            type="submit"
            text="Save changes"
            icon="disk"
            iconPosition="prefix"
            variant={VARIANT.PRIMARY}
            size={SIZE.LARGE}
            loading={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

ChangePassword.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    userType: PropTypes.shape({
      __typename: PropTypes.string,
      name: PropTypes.string,
    }),
    __typename: PropTypes.string,
  }).isRequired,
};

export default ChangePassword;
