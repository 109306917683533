import React from 'react';
import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';

import { Bar, Meter } from './style';

const PasswordStrengthBar = ({ password }) => {
  const meter = zxcvbn(password);

  return (
    <Bar>
      <Meter strength={meter.score} value={password} />
    </Bar>
  );
};

PasswordStrengthBar.propTypes = {
  password: PropTypes.string.isRequired,
};

export default PasswordStrengthBar;
