/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Text from '@common/Text';
import { Header, Wrapper, Section } from './style';

const ToggleableSection = ({ children, title, opened }) => {
  const [toggled, setToggled] = useState(opened);

  return (
    <Wrapper>
      <Header
        type="button"
        onClick={() => setToggled(!toggled)}
        toggled={toggled}
      >
        <Text type="H4">{title}</Text>
        <svg
          width="8"
          height="5"
          viewBox="0 0 8 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.66104 0.434448C8.04571 0.434448 8.40637 1.23559 7.50794 2.24503L7.40685 2.35396L5.85615 3.95939C4.86678 4.98366 3.30855 5.01835 2.27917 4.08642L2.148 3.95939L0.597293 2.35396C-0.399487 1.322 -0.114747 0.493376 1.19848 0.437457L1.3431 0.434448H6.66104Z"
            fill="#005AD4"
          />
        </svg>
      </Header>
      {toggled ? <Section>{children}</Section> : null}
    </Wrapper>
  );
};

ToggleableSection.defaultProps = {
  opened: false,
};

ToggleableSection.propTypes = {
  title: PropTypes.string.isRequired,
  opened: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ToggleableSection;
