import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';
import { createToast, TOAST_TYPES } from '@common/Toast';
import { DOCUMENT, USER } from '@graphQL/queries';
import Modal from '@common/Modal';
import routes from '@routes';
import Loader from '@common/Loader';
import Text from '@common/Text';
import {
  PageButtons,
  Table,
  Body,
  Cell,
  Head,
  NoItems,
  Spacer,
  Row,
  Footer,
  NextPage,
  PrevPage,
  Wrapper,
} from '@components/authenticated/Documents/style';
import HeaderCell from './components/HeaderCell';
import StatusCell from './components/StatusCell';
import ReviewModal from './components/ReviewModal';

const Documents = ({
  loading,
  items,
  totalCount,
  hasNextPage,
  hasPrevPage,
  nextPageHandler,
  prevPageHandler,
  queryVariables,
  setQueryVariables,
}) => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { location, replace, push } = useHistory();
  const { data: userQuery, loading: userQueryLoading } = useQuery(USER);

  const client = useApolloClient();
  const showGroupColumn = get(userQuery, 'user.client.areGroupsEnabled');

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const {
          data: { document: documentToRewiew },
        } = await client.query({
          query: DOCUMENT,
          variables: { documentId: location.state.review },
        });

        if (documentToRewiew && documentToRewiew.status === 'UPLOADED') {
          openReviewModal(documentToRewiew);
          replace(routes.home);
        }
      } catch (error) {
        createToast(error.message, TOAST_TYPES.ERROR);
      }
    };

    if (location.state && location.state.review) {
      fetchDocument();
    }
  }, [location, replace, client]);

  const openReviewModal = (document) => {
    setSelectedDocument(document);
    setModalVisible(true);
  };

  const closeReviewModal = () => {
    setModalVisible(false);
    setSelectedDocument(null);
  };

  const toggleSort = (column) => {
    let so = sortOrder;
    if (column === sortColumn) {
      so = so === 'asc' ? 'desc' : 'asc';
    } else {
      so = 'asc';
    }

    setSortColumn(column);
    setSortOrder(so);
    const orderBy = `${so === 'asc' ? '' : '-'}${column}`;
    setQueryVariables({ ...queryVariables, orderBy });
  };

  const redirectToDocumentDetails = (id) => {
    push(routes.document(id));
  };

  return (
    <Wrapper>
      {loading || userQueryLoading ? <Loader cover /> : null}
      <Table>
        <Head>
          <tr>
            <th style={{ width: '150px' }}>
              <HeaderCell text="Doc ID" />
            </th>
            <th>
              <HeaderCell
                text="Filename"
                onClick={() => toggleSort('originalFilename')}
                sort={sortColumn === 'originalFilename' ? sortOrder : null}
              />
            </th>
            <th>
              <HeaderCell
                text="Name"
                onClick={() => toggleSort('name')}
                sort={sortColumn === 'name' ? sortOrder : null}
              />
            </th>
            <th style={{ width: '200px' }}>
              <HeaderCell
                text="Contract type"
                onClick={() => toggleSort('documentType')}
                sort={sortColumn === 'documentType' ? sortOrder : null}
              />
            </th>
            <th style={{ width: '150px' }}>
              <HeaderCell
                text="Upload date"
                onClick={() => toggleSort('uploadDate')}
                sort={sortColumn === 'uploadDate' ? sortOrder : null}
              />
            </th>
            {showGroupColumn ? (
              <th>
                <HeaderCell
                  text="Group"
                  onClick={() => toggleSort('group')}
                  sort={sortColumn === 'group' ? sortOrder : null}
                />
              </th>
            ) : null}
            <th style={{ width: '200px' }}>
              <HeaderCell
                text="Review"
                onClick={() => toggleSort('status')}
                sort={sortColumn === 'status' ? sortOrder : null}
              />
            </th>
          </tr>
        </Head>

        {items && items.length > 0 ? (
          <Body>
            <tr>
              <Spacer colspan="6" />
            </tr>
            {items.map(({ node }) => (
              <Row
                key={node.id}
                onClick={() => redirectToDocumentDetails(node.id)}
              >
                <Cell style={{ width: '150px' }}>{node.documentId}</Cell>
                <Cell active={sortColumn === 'originalFilename'}>
                  {node.originalFilename}
                </Cell>
                <Cell active={sortColumn === 'name'}>
                  {node.name ? node.name : '-'}
                </Cell>
                <Cell
                  active={sortColumn === 'contractType'}
                  style={{ width: '200px' }}
                >
                  {node.documentType ? node.documentType.name : '-'}
                </Cell>
                <Cell
                  active={sortColumn === 'uploadDate'}
                  style={{ width: '150px' }}
                >
                  {moment(node.createdAt).format('DD/MM/YYYY')}
                </Cell>
                {showGroupColumn ? (
                  <Cell active={sortColumn === 'group'}>
                    {node.group ? node.group.name : '-'}
                  </Cell>
                ) : null}
                <Cell
                  active={sortColumn === 'review'}
                  style={{ width: '200px' }}
                >
                  <StatusCell
                    document={node}
                    openReviewModal={openReviewModal}
                  />
                </Cell>
              </Row>
            ))}
          </Body>
        ) : null}
        {loading && (!items || items.length === 0) ? (
          <Body>
            <Row>
              <Cell colspan="7" makeSomeSpace />
            </Row>
          </Body>
        ) : null}
      </Table>

      {items && !items.length && !loading ? (
        <NoItems>No documents</NoItems>
      ) : (
        <>
          <Footer>
            <Text weight="bold" size="small">
              Total: {totalCount}
            </Text>
            <PageButtons>
              <PrevPage
                type="button"
                onClick={prevPageHandler}
                disabled={!hasPrevPage}
              >
                prev
              </PrevPage>
              <NextPage
                type="button"
                onClick={nextPageHandler}
                disabled={!hasNextPage}
              >
                next
              </NextPage>
            </PageButtons>
          </Footer>
        </>
      )}

      {modalVisible ? (
        <Modal close={closeReviewModal}>
          <ReviewModal document={selectedDocument} close={closeReviewModal} />
        </Modal>
      ) : null}
    </Wrapper>
  );
};

Documents.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number.isRequired,
  hasPrevPage: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  nextPageHandler: PropTypes.func.isRequired,
  prevPageHandler: PropTypes.func.isRequired,
  queryVariables: PropTypes.shape({
    filename: PropTypes.string,
    orderBy: PropTypes.string,
    first: PropTypes.number,
    last: PropTypes.number,
  }).isRequired,
  setQueryVariables: PropTypes.func.isRequired,
};

export default Documents;
