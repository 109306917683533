import styled from '@emotion/styled';
import { getTypography, getFonts, getColors } from '@styles/helpers';
import { breakpoint } from '@styles/breakpoints';

export const Display = styled.span`
  font-size: ${(props) => getTypography(props).display};
  font-weight: ${(props) => getFonts(props).bold};
  letter-spacing: -1.6px;
  color: ${(props) => getColors(props).text[props.color]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  @media ${breakpoint.md} {
    font-size: 30px;
  }
`;

export const H1 = styled.h1`
  font-size: ${(props) => getTypography(props).h1};
  font-weight: ${(props) => getFonts(props).bold};
  letter-spacing: -1.2px;
  line-height: 60px;
  color: ${(props) => getColors(props).text[props.color]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  @media ${breakpoint.md} {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const H2 = styled.h2`
  font-size: ${(props) => getTypography(props).h2};
  font-weight: ${(props) => getFonts(props).bold};
  letter-spacing: -0.8px;
  line-height: 46px;
  color: ${(props) => getColors(props).text[props.color]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export const H3 = styled.h3`
  font-size: ${(props) => getTypography(props).h3};
  font-weight: ${(props) => getFonts(props).bold};
  letter-spacing: -0.4px;
  line-height: 32px;
  color: ${(props) => getColors(props).text[props.color]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  @media ${breakpoint.md} {
    font-size: 20px;
  }
`;

export const H4 = styled.h4`
  font-size: ${(props) => getTypography(props).h4};
  font-weight: ${(props) => getFonts(props).bold};
  line-height: 26px;
  letter-spacing: -0.2px;
  color: ${(props) => getColors(props).text[props.color]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export const H5 = styled.h5`
  font-size: ${(props) => getTypography(props).h5};
  font-weight: ${(props) => getFonts(props).bold};
  letter-spacing: -0.1px;
  color: ${(props) => getColors(props).text[props.color]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};

  @media ${breakpoint.md} {
    font-size: 13px;
  }
`;

export const H6 = styled.h6`
  font-size: ${(props) => getTypography(props).h6};
  font-weight: ${(props) => getFonts(props).bold};
  letter-spacing: 0;
  color: ${(props) => getColors(props).text[props.color]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export const Text = styled.span`
  font-size: ${(props) => getTypography(props)[props.size]};
  font-weight: ${(props) => getFonts(props).normal};
  ${(props) => {
    let spacing = -0.3;
    let lh = 22;
    switch (props.size) {
      case 'normal':
        spacing = -0.2;
        lh = 22;
        break;
      case 'small':
        spacing = -0.1;
        lh = 18;
        break;
      case 'big':
        spacing = -0.3;
        lh = 30;
        break;
      default:
        spacing = -0.2;
        lh = 22;
    }
    return `
    letter-spacing: ${spacing}px;
    line-height: ${lh}px;
    `;
  }}
  color: ${(props) => getColors(props).text[props.color]};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  font-weight: ${(props) => getFonts(props)[props.weight]};
`;
