import styled from '@emotion/styled';

export const Wrapper = styled.div`
  z-index: 99;

  ${(props) => {
    if (props.fixed) {
      return `
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
      `;
    }
    if (props.relative) {
      return `
      position: relative;
    `;
    }

    return `
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `;
  }}

  border-radius: 100%;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05), 1px 2px 1px rgba(0, 0, 0, 0.03),
    0px 1px 1px rgba(0, 0, 0, 0.04);
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Cover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 14%
  );
  pointer-events: none;
`;
