import styled from '@emotion/styled';
import { getBackgrounds } from '@styles/helpers';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  background-color: ${(props) => getBackgrounds(props).light.secondary};
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.05),
    0px 9px 46px rgba(0, 0, 0, 0.03), 0px 24px 38px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  position: relative;
  width: 90%;
  max-width: 790px;
  padding: 32px;
  z-index: 2;

  @media ${breakpoint.md} {
    padding: 15px;
  }
`;

export const Cover = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: #1c1c1c;
  opacity: 0.8;
`;

export const CloseBtn = styled.button`
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  right: 32px;
  top: 32px;
  cursor: pointer;
  padding: 0;
`;

export const Content = styled.div`
  max-height: 80vh;
  overflow: auto;
`;
