import { gql } from '@apollo/client';

export const LOGIN_TWO_FACTOR = gql`
  mutation($input: LoginTwoFactorMutationInput!) {
    loginTwoFactor(input: $input) {
      authenticationToken {
        accessToken
        refreshToken
      }
      success
    }
  }
`;
