import React, { useCallback, useState, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import omitBy from 'lodash/omitBy';
import {
  GENERATE_DOCUMENT_UPLOAD_URL,
  CREATE_DOCUMENT,
} from '@graphQL/mutations';
import get from 'lodash/get';

import { DOCUMENT_TYPES, GROUPS, USER } from '@graphQL/queries';
import axios from '@axios';
import { createToast, TOAST_TYPES } from '@components/common/Toast';
import routes from '@routes';

import Layout from '../layout';
import Dropzone from './components/Dropzone';
import UploadForm from './components/Form';
import UploadProgress from './components/Progress';

const Upload = () => {
  const history = useHistory();
  const { error, data: userQuery } = useQuery(USER);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [documentGroups, setDocumentGroups] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [signedUrl, setSignedUrl] = useState(null);
  const [document, setDocument] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);

  if (error) {
    createToast(error.message, TOAST_TYPES.ERROR);
  }

  const areGroupsEnabled = get(userQuery, 'user.client.areGroupsEnabled');

  const client = useApolloClient();
  const onDrop = useCallback(
    async (acceptedFiles) => {
      try {
        if (acceptedFiles.length) {
          setFile(acceptedFiles[0]);
          const filename = acceptedFiles[0].name;
          const {
            data: { generateDocumentUploadUrl },
          } = await client.mutate({
            mutation: GENERATE_DOCUMENT_UPLOAD_URL,
            variables: {
              input: {
                filename,
              },
            },
          });

          const { data: documentTypesResponse } = await client.query({
            query: DOCUMENT_TYPES,
          });
          const { data: documentGroupsResponse } = await client.query({
            query: GROUPS,
          });
          setDocumentTypes(
            documentTypesResponse.documentTypes.map((dt) => ({
              value: dt.id,
              label: dt.name,
            })),
          );
          setDocumentGroups(
            documentGroupsResponse.groups.edges.map(({ node: dg }) => ({
              value: dg.id,
              label: dg.name,
            })),
          );

          setSignedUrl(generateDocumentUploadUrl.signedUrl);
          setDocument(generateDocumentUploadUrl.document);
          setShowUploadForm(true);
        } else {
          createToast('Please select correct file format.', TOAST_TYPES.INFO);
        }
      } catch (err) {
        console.log(err); // eslint-disable-line
      }
    },
    [client],
  );

  const reset = () => {
    setShowUploadForm(false);
    setDocumentGroups([]);
    setDocumentTypes([]);
    setSignedUrl(null);
    setDocument(null);
    setFile(null);
    setUploadProgress(null);
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const submitHandler = async (values, setErrors) => {
    try {
      setUploadProgress(1);
      setShowUploadForm(false);

      const uploadResponse = await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: ({ loaded, total }) => {
          setUploadProgress(Math.round((loaded / total) * 100));
        },
      });

      if (uploadResponse.status === 200) {
        const {
          data: {
            createDocument: { document: apiResponse },
          },
        } = await client.mutate({
          mutation: CREATE_DOCUMENT,
          variables: {
            input: {
              ...omitBy(values, (value, key) => key === 'submitForReview'),
            },
          },
        });

        if (apiResponse.status === 'UPLOADED') {
          setUploadProgress(null);
          history.push({
            pathname: routes.home,
            state: values.submitForReview ? { review: values.document } : null,
          });
          createToast('Document uploaded Successfully', TOAST_TYPES.SUCCESS);
        }
      }
    } catch (err) {
      createToast(err.message, TOAST_TYPES.ERROR);
      reset();
    }
  };

  const renderContent = () => {
    if (showUploadForm) {
      return (
        <UploadForm
          areGroupsEnabled={areGroupsEnabled}
          documentId={document.id}
          documentGroups={documentGroups}
          documentTypes={documentTypes}
          file={file}
          reset={reset}
          onSubmit={submitHandler}
        />
      );
    }
    if (uploadProgress) {
      return <UploadProgress progress={uploadProgress} />;
    }

    return <Dropzone onDrop={onDrop} />;
  };

  return (
    <Layout title="Upload" surfacePadding="60px">
      {renderContent()}
    </Layout>
  );
};

export default Upload;
