import React from 'react';
import { useDropzone } from 'react-dropzone';
import Text from '@common/Text';
import Button, { VARIANT } from '@common/Button';
import { FilesIcon, Row } from './style';
import { FormWrapper } from '../../style';

const Dropzone = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept:
      'application/doc, application/ms-doc, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf', // eslint-disable-line max-len
  });

  return (
    <FormWrapper active={isDragActive} {...getRootProps()}>
      <input {...getInputProps()} />
      <Text type="H3">Upload a contract</Text>

      <FilesIcon>
        <img
          src={
            isDragActive ? 'images/icons/drop.svg' : 'images/icons/files.svg'
          }
          alt="drop"
        />
      </FilesIcon>

      <Row>
        <Text type="H6" color={300} center>
          Drag a document here to upload
        </Text>
        <Text type="H6" color={300} center>
          Files should be docx format.
        </Text>
      </Row>

      <Button
        variant={VARIANT.PRIMARY}
        icon="upload"
        iconPosition="prefix"
        text="Browse for file"
      />
    </FormWrapper>
  );
};

export default Dropzone;
