import styled from '@emotion/styled';
import { Form as FormikForm } from 'formik';
import { Scrollbar } from 'react-scrollbars-custom';
import { breakpoint } from '@styles/breakpoints';

export const Form = styled(FormikForm)`
  margin: 0 auto;
  width: 100%;
  max-width: 350px;
`;

export const ButtonRow = styled.div`
  margin-top: 40px;
`;

export const Header = styled.div`
  margin-bottom: 40px;
`;

export const UserSelector = styled.div`
  margin-top: 30px;
`;

export const UserList = styled.div``;

export const UserBox = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 9px 15px;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  background-color: #fff;
  transition: border 0.3s ease-in-out;
  align-items: center;

  > span {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.3px;

    @media ${breakpoint.md} {
      font-size: 15px;
      line-height: 20px;
    }
  }

  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    transition: background 0.3s ease-in-out;
    background: url('images/icons/user-gray.svg') no-repeat 50% 50% / contain;
  }
`;

export const UserItem = styled.label`
  position: relative;
  display: block;
  position: relative;

  & + & {
    margin-top: 10px;
  }

  > input {
    position: absolute;
    left: -9999px;

    &:checked {
      & + ${UserBox} {
        border-color: #5aaf32;

        &:after {
          background-image: url('images/icons/check.svg');
        }
      }
    }
  }

  &:last-of-type {
    margin-bottom: 49px;
  }
`;

export const ScrollableList = styled(Scrollbar)`
  height: 250px !important;

  margin-right: -30px;
  width: calc(100% + 30px) !important;
  padding-right: 30px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 49px;
    bottom: 0;
    z-index: 2;
    background: linear-gradient(
      360deg,
      #f7f7f7 22.5%,
      rgba(247, 247, 247, 0) 77.5%
    );
  }

  .ScrollbarsCustom-Wrapper {
    max-width: 350px;
  }

  .ScrollbarsCustom-Track {
    top: 0 !important;
    height: 100% !important;
    z-index: 6;
    width: 6px !important;
  }

  @media ${breakpoint.md} {
    width: 100% !important;
  }
`;

export const CurrentUsersList = styled.div`
  margin: 15px 0 30px;

  ${UserItem} {
    margin-bottom: 0 !important;
  }
`;
