import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Text from '@common/Text';
import routes from '@routes';
import { Link } from 'react-router-dom';

import {
  BurgerMenu,
  Wrapper,
  Header,
  Logo,
  Navigation,
  NavigationLink,
  MobileCover,
} from './style';

const NAV_ITEMS = [
  { label: 'Home', icon: 'home', route: routes.home },
  { label: 'Upload', icon: 'upload', route: routes.upload },
  { label: 'Users', icon: 'users', route: routes.users },
  { label: 'Groups', icon: 'groups', route: routes.groups },
  { label: 'Settings', icon: 'settings', route: routes.settings },
];

const Sidebar = ({ areGroupsEnabled }) => {
  const [navigationVisible, setNavigationVisible] = useState(false);

  return (
    <Wrapper>
      <Header>
        <BurgerMenu type="button" onClick={() => setNavigationVisible(true)} />
        <Link to={routes.home}>
          <Logo src="images/robin-logo-white-bird-only.png" alt="robin" />
        </Link>
      </Header>

      <MobileCover
        visible={navigationVisible}
        onClick={() => setNavigationVisible(false)}
      />

      <Navigation visible={navigationVisible}>
        {NAV_ITEMS.map((item) => {
          if (!areGroupsEnabled && item.label === 'Groups') return null;

          return (
            <NavigationLink
              key={`sidebar-nav_${item.label}`}
              activeClassName="active"
              to={item.route}
            >
              <img src={`images/icons/${item.icon}.svg`} alt={item.label} />
              <Text type="H5" color="white">
                {item.label}
              </Text>
            </NavigationLink>
          );
        })}
      </Navigation>
    </Wrapper>
  );
};

Sidebar.propTypes = {
  areGroupsEnabled: PropTypes.bool.isRequired,
};

export default Sidebar;
