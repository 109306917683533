import styled from '@emotion/styled';
import { getColors, getPalette } from '@styles/helpers';

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-right: 6px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  span {
    margin-right: 6px;
  }
`;

export const Wrapper = styled.div`
  color: ${(props) => (props.withError ? getColors(props).error : '#585757')};
  width: 100%;
  position: relative;

  ${(props) =>
    props.withError
      ? `
        ${Title}, ${Label} {
          span {
            color: ${getColors(props).error}
          }
        }
      `
      : null}
`;

export const Group = styled.div`
  display: flex;
  display: flex;
  align-items: center;
`;

export const GroupItem = styled.div`
  position: relative;
  margin-right: 20px;
`;

export const Error = styled.div`
  color: ${(props) => getColors(props).error};
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  bottom: -24px;
`;

export const Control = styled.div`
  background-color: #dbdbdb;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  margin-right: 10px;

  background: #ffffff;
  border: 1px solid #dbdbdb;

  &:before {
    width: 8px;
    height: 8px;
    background-color: #fff;
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    border-radius: 4px;
  }
`;

export const Input = styled.input`
  height: ${(props) => (props.textSize === 'large' ? '48px' : '40px')};
  width: 1px;
  height: 1px;
  position: absolute;
  left: -9999px;

  &:checked {
    & + ${Label} {
      ${Control} {
        background-color: ${(props) => getPalette(props).base};
        border-color: ${(props) => getPalette(props).base};
      }
    }
  }

  &:disabled {
    & + ${Label} {
      ${Control} {
        background-color: #f7f7f7;
        border-color: #f7f7f7;

        &:before {
          background-color: #dbdbdb;
        }
      }
    }
  }
`;
