import styled from '@emotion/styled';
import { getColors, getFonts, getPalette } from '@styles/helpers';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  max-width: 200px;
  min-height: 216px;
  margin: 7px;
  width: 100%;
  border: 1px solid ${(props) => (props.isPrivate ? '#BB6BD9' : '#f2c94c')};
  padding: 12px;

  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05), 0px 1px 18px rgba(0, 0, 0, 0.03),
    0px 6px 10px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  @media ${breakpoint.sm} {
    max-width: unset;
    margin: 5px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const Footer = styled.div`
  border-top: 1px solid rgba(219, 219, 219, 0.5);
  margin: 0 -12px;
  padding: 18px 12px 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Header = styled.div`
  padding: 5px 12px 15px;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  margin: 0 -12px;

  > h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
`;

export const CounterWrapper = styled.div`
  flex: 1;
  text-align: center;
`;

export const Counter = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: ${(props) => getFonts(props).bold};
  margin-bottom: 8px;
  ${(props) => {
    let color = getPalette(props).amber;

    if (props.green) {
      color = getPalette(props).green;
    }

    return `color: ${color};`;
  }}

  ${(props) =>
    props.count === 0 ? `color: ${getColors(props).text[400]};` : null};
`;

export const CounterTitle = styled.div`
  font-size: 11px;
  line-height: 12px;
  color: ${(props) => getColors(props).text[300]};
  font-weight: ${(props) => getFonts(props).bold};
`;
