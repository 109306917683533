import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import {
  UserItem,
  UserBox,
} from '@components/authenticated/Groups/components/NewGroupCreator/style';

const UserCheckbox = ({ user }) => (
  <UserItem htmlFor={`user-${user.id}`}>
    <Field
      name="users"
      value={user.id}
      type="checkbox"
      id={`user-${user.id}`}
    />
    <UserBox>
      <span>
        {user.firstName} {user.lastName}
      </span>
    </UserBox>
  </UserItem>
);

UserCheckbox.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserCheckbox;
