import { gql } from '@apollo/client';

export const SETUP_ACCOUNT = gql`
  mutation($input: SetupAccountMutationInput!) {
    setupAccount(input: $input) {
      success
      authenticationStep {
        token
        nextStep
      }
    }
  }
`;
