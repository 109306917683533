import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { download } from '@helpers';

import { ActionButton } from './style';

const StatusCell = ({ document, openReviewModal }) => {
  const client = useApolloClient();

  switch (document.status) {
    case 'UPLOADED':
      return (
        <ActionButton
          type="button"
          onClick={(ev) => {
            ev.stopPropagation();
            openReviewModal(document);
          }}
        >
          <img src="images/icons/send.svg" alt="send" />
          Send to Robin
        </ActionButton>
      );
    case 'REVIEW':
      return (
        <ActionButton type="button" c="amber">
          <img src="images/icons/processing.svg" alt="send" />
          Processing...
        </ActionButton>
      );
    case 'REVIEWED':
      return (
        <ActionButton
          type="button"
          c="green"
          onClick={(ev) => {
            ev.stopPropagation();
            download(client, document);
          }}
        >
          <img src="images/icons/download-green.svg" alt="send" />
          Revised
        </ActionButton>
      );

    default:
      return <span>{document.status}</span>;
  }
};

StatusCell.propTypes = {
  document: PropTypes.object.isRequired,
  openReviewModal: PropTypes.func.isRequired,
};

export default StatusCell;
