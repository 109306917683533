import { gql } from '@apollo/client';

export const CREATE_DOCUMENT = gql`
  mutation($input: CreateDocumentMutationInput!) {
    createDocument(input: $input) {
      document {
        id
        status
      }
      clientMutationId
    }
  }
`;
