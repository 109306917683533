import FileSaver from 'file-saver';
import { createToast, TOAST_TYPES } from '@components/common/Toast';

import axios from '@axios';
import { DOWNLOAD_DOCUMENT_FILE } from '@graphQL/queries';

export const download = async (client, document, docType = 'REVIEWED_FILE') => {
  let ProgressBox;
  try {
    const {
      data: { downloadDocumentFile },
    } = await client.query({
      query: DOWNLOAD_DOCUMENT_FILE,
      variables: {
        documentId: document.id,
        fileType: docType,
      },
    });

    if (downloadDocumentFile) {
      ProgressBox = window.document.createElement('div');
      const ProgressBarPane = window.document.createElement('div');
      const ProgressBar = window.document.createElement('div');

      ProgressBox.setAttribute('class', 'download-box');
      ProgressBarPane.setAttribute('class', 'download-box__progress');
      ProgressBarPane.appendChild(ProgressBar);
      ProgressBox.textContent = 'DOWNLOADING';
      ProgressBox.appendChild(ProgressBarPane);
      window.document.body.appendChild(ProgressBox);
      setTimeout(() => {
        ProgressBox.classList.add('animate');
      }, 100);

      const { data } = await axios(downloadDocumentFile, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },

        onDownloadProgress: ({ loaded, total }) => {
          const progress = Math.round((loaded / total) * 100);
          ProgressBar.style.width = `${progress}%`;
          if (progress === 100) {
            ProgressBox.classList.remove('animate');
            setTimeout(() => {
              ProgressBox.remove();
            }, 200);
          }
        },
      });

      const blob = new Blob([data], {
        type: 'application/octet-stream',
      });
      FileSaver.saveAs(blob, document.originalFilename);
    }
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    if (ProgressBox) {
      ProgressBox.classList.remove('animate');
    }
    createToast(error, TOAST_TYPES.ERROR);
  }
};
