import { gql } from '@apollo/client';

export const GENERATE_DOCUMENT_UPLOAD_URL = gql`
  mutation($input: DocumentUploadMutationInput!) {
    generateDocumentUploadUrl(input: $input) {
      signedUrl
      document {
        id
      }
    }
  }
`;
