import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import mime from 'mime-types';
import Text from '@common/Text';
import TextField from '@components/common/Form/TextField';
import SelectField from '@components/common/Form/Select';
import RadioGroup from '@components/common/Form/Radio/group';
import Button, { VARIANT, SIZE } from '@common/Button';
import Loader from '@common/Loader';

import { FormWrapper } from '../../style';
import { File, FileDetails, FileDelete, Form, Title, ButtonRow } from './style';

const SEND_TO_REVIEW_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const UploadForm = ({
  areGroupsEnabled,
  documentId,
  documentTypes,
  documentGroups,
  file,
  onSubmit,
  reset,
}) => {
  const initialValues = {
    document: documentId,
    name: file.name,
    documentType: '',
    uploadComment: '',
    submitForReview: '',
  };

  if (areGroupsEnabled) {
    initialValues.group = '';
  }

  return (
    <FormWrapper>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          const required = ['documentType', 'name', 'submitForReview'];

          if (areGroupsEnabled) required.push('group');

          required.forEach((field) => {
            if (values[field] === '') {
              errors[field] = 'Required';
            }
          });

          return errors;
        }}
        onSubmit={async (values, { setErrors }) => {
          await onSubmit(values, setErrors);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            {isSubmitting ? <Loader /> : null}
            <Title>
              <Text type="H3" center>
                Upload a contract
              </Text>
            </Title>

            <File>
              <img src="images/icons/file.svg" alt="file" />
              <FileDetails>
                <Text type="H5">{file.name}</Text>
                <Text type="Text" size="small" color={300}>
                  .{mime.extension(file.type)}
                </Text>
              </FileDetails>
              <FileDelete type="button" onClick={reset}>
                <img src="images/icons/cross.svg" alt="remove" />
              </FileDelete>
            </File>

            <TextField name="name" label="Document Name" />

            <SelectField
              name="documentType"
              options={documentTypes}
              label="Document type"
            />
            {areGroupsEnabled ? (
              <SelectField
                name="group"
                options={documentGroups}
                label="Group document in"
              />
            ) : null}
            <RadioGroup
              name="submitForReview"
              label="Submit for Robin review"
              infoTip="If you want to store the document and submit it for review later, choose 'no'."
              options={SEND_TO_REVIEW_OPTIONS}
            />
            <ButtonRow>
              <Button
                type="submit"
                text="Upload"
                icon="arrow-up"
                variant={VARIANT.PRIMARY}
                size={SIZE.LARGE}
                loading={isSubmitting}
                disabled={isSubmitting}
              />
            </ButtonRow>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

UploadForm.propTypes = {
  areGroupsEnabled: PropTypes.bool.isRequired,
  documentId: PropTypes.string.isRequired,
  documentTypes: PropTypes.array.isRequired,
  documentGroups: PropTypes.array.isRequired,
  file: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default UploadForm;
